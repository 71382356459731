export default function LoginSeparatorLeft() {
  return (
    <svg
      width="155"
      height="1"
      viewBox="0 0 155 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="154.5"
        height="1"
        transform="matrix(-1 0 0 1 155 0)"
        fill="url(#paint0_linear_123_1818)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_123_1818"
          x1="0"
          y1="0.5"
          x2="154.5"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
