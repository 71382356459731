import React from 'react';

const LoginInput = ({ children, suffixIcon, ...props }) => {
  console.log(suffixIcon);
  return (
    <div className="flex flex-col gap-2">
      <label className="font-semibold text-md text-buddy-text-1">
        {props.label}
      </label>
      <div className="relative select-none">
        <input
          className="w-full h-11 bg-transparent ring-1 ring-white text-lg text-buddy-text-1 rounded-lg ps-4 focus:ring-1 focus:ring-inset placeholder:text-white placeholder:opacity-70"
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          autoComplete="new-password"
          aria-autocomplete="none"
          list="autocompleteOff"
        />
        {suffixIcon && (
          <div className="absolute right-4 top-0 h-full w-6 flex items-center justify-center">
            {suffixIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginInput;
