import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { StatusBar } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';

const CLIENT_ID =
  '909562975875-dn7h3ihlupjdgb6hnj56illp2dc6jvek.apps.googleusercontent.com';

function App({ children }) {
  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: false });
    Keyboard.setAccessoryBarVisible({ isVisible: false });
  }, []);
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <div className="p-0 max-w-xl mx-auto">{children}</div>
    </GoogleOAuthProvider>
  );
}

export default App;
