import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import User from '../api/services/User';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/solid'
import { ShieldCheckIcon, LanguageIcon, BellIcon, CalendarIcon , CreditCardIcon , ChatBubbleBottomCenterIcon , QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import DangerButton from './ui/DangerButton';

const SidebarMenu = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [userAvatarUrl, setUserAvatarUrl] = useState('');
  const [options, setOptions] = useState([]);
  const [genderId, setGenderId] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data'));
    data.gender_id = genderId;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm3');
  }

  useEffect(() => {
    getProfile();
    setUserAvatarUrl("https://ui-avatars.com/api/?rounded=true&name=" + user.name);
  }, []);

  const handleOnChange = (value) => {
    setGenderId(value);
  }

  const getProfile = async () => {
      try {
          const response = await User.profile();
          setUserEmail(response.data.email);
      } catch (error) {
          console.error('Error fetching profile:', error);
      }
  };

  const handleLogoutButton = (e) => {
    if (window.confirm("¿Cerrar sesión?") == true) {
      localStorage.setItem('user', null);
      localStorage.setItem('token', null);
      navigate('/login');
    }
  }

  const handleDeleteAccountButton = async (e) =>  {
    if (window.confirm("¿Eliminar tu cuenta? Todos los datos asociados serán eliminados y no podrán ser recuperados.") == true) {
      try {
        await User.delete(user.id);
        localStorage.setItem('user', null);
        localStorage.setItem('token', null);
        navigate('/login');
      } catch (error) {
        alert('Ocurrió un error eliminando tu cuenta.');
      }
    }
  }

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={"/"}>Editar Perfil</Toolbar>
          <Link className='w-full' to={"/userProfile"}>
            <div className='w-full flex justify-between space-x-4 items-center'>
              <div className="relative overflow-hidden h-14 w-14 rounded-full bg-black"> 
                <img src={user?.image_url} alt="" className="bg-black h-14 w-14 object-cover object-center" />
              </div>
              <div className='grow'>
                <span className='text-primary font-semibold text-lg'>{ user.name }</span><br />
                {userEmail&& (
                  <span className='text-buddy-text-2 text-md'>{ userEmail }</span>
                )}
              </div>
            </div>
          </Link>

          <h2 className='w-full text-buddy-text-3 font-semibold mt-6'>Calendario de Pago & Métodos de Pago</h2>

          <Link className='w-full' to={'/subscription'}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <CalendarIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Suscripción</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <Link className='w-full' to={'/paymentmethods'}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <CreditCardIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Métodos de Pago</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <h2 className='w-full text-buddy-text-3 font-semibold mt-6'>Seguridad de la cuenta</h2>
          
          <button className='w-full' onClick={handleLogoutButton}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <ArrowLeftStartOnRectangleIcon className='size-6 text-buddy-text-1 grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-danger font-semibold grow-0'>Cerrar sesión</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </button>

          <Link className='w-full' to={'/security'}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <ShieldCheckIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Seguridad</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <h2 className='w-full text-buddy-text-3 font-semibold mt-3'>Configuración de la APP</h2>
            
          <Link className='w-full' to={'/userLanguageSelect'}>
              <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                <LanguageIcon className='size-6 text-white grow-0' />
                <div className='grow px-2 text-start'>
                  <span className='text-buddy-text-1 font-semibold grow-0'>Idioma</span>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
              </div>
          </Link>

          <Link className='w-full' to={'/userNotificationSelect'}>
              <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                <BellIcon className='size-6 text-white grow-0' />
                <div className='grow px-2 text-start'>
                  <span className='text-buddy-text-1 font-semibold grow-0'>Notificaciones</span>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
              </div>
          </Link>

          <h2 className='w-full text-buddy-text-3 font-semibold mt-3'>Centro de Ayuda</h2>
            
          <Link className='w-full'>
              <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                <ChatBubbleBottomCenterIcon className='size-6 text-white grow-0' />
                <div className='grow px-2 text-start'>
                  <span className='text-buddy-text-1 font-semibold grow-0'>Chat en línea</span>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
              </div>
          </Link>

          <Link className='w-full' to={'/userFaqs'}>
              <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                <QuestionMarkCircleIcon className='size-6 text-white grow-0' />
                <div className='grow px-2 text-start'>
                  <span className='text-buddy-text-1 font-semibold grow-0'>Preguntas frecuentes</span>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
              </div>
          </Link>
          
          <FooterToolbar>
            <DangerButton type="button" onClick={handleDeleteAccountButton} >Eliminar cuenta</DangerButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;