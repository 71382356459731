import React from 'react';
import ButtonWithImage from '../ButtonWithImage';
import Celery from '../../img/allergies/celery.png';
import Egg from '../../img/allergies/egg.png';
import Fish from '../../img/allergies/fish.png';
import Fructose from '../../img/allergies/fructose.png';
import Gluten from '../../img/allergies/gluten.png';
import Lactose from '../../img/allergies/lactose.png';
import Lupine from '../../img/allergies/lupine.png';
import Mustard from '../../img/allergies/mustard.png';
import Nuts from '../../img/allergies/nuts.png';
import Peanut from '../../img/allergies/peanut.png';
import SeaFood from '../../img/allergies/seafood.png';
import Sesame from '../../img/allergies/sesame.png';
import Soy from '../../img/allergies/soy.png';
import Flexitaria from '../../img/diet/flexitaria.png';
import Halal from '../../img/diet/halal.png';
import Hindu from '../../img/diet/hindu.png';
import Keto from '../../img/diet/keto.png';
import Kosher from '../../img/diet/kosher.png';
import LowMercury from '../../img/diet/lowMercury.png';
import LowSodium from '../../img/diet/lowSodium.png';
import Ovolactovegeratian from '../../img/diet/ovolactovegetarian.png';
import Vegan from '../../img/diet/vegan.png';
import Vegetarian from '../../img/diet/vegetarian.png';
import American from '../../img/cuisine/american.png';
import Argentine from '../../img/cuisine/argentine.png';
import Chinese from '../../img/cuisine/chinese.png';
import French from '../../img/cuisine/french.png';
import Greek from '../../img/cuisine/greek.png';
import Indian from '../../img/cuisine/indian.png';
import Italian from '../../img/cuisine/italian.png';
import Japanese from '../../img/cuisine/japanese.png';
import Mexican from '../../img/cuisine/mexican.png';
import Moroccan from '../../img/cuisine/moroccan.png';
import Peruvian from '../../img/cuisine/peruvian.png';
import Spanish from '../../img/cuisine/spanish.png';
import Thai from '../../img/cuisine/thai.png';

const IMAGE_MAP = {
  'celery-free': Celery,
  'crustacean-free': SeaFood,
  'dairy-free': Lactose,
  'egg-free': Egg,
  'fructose-free': Fructose,
  'fish-free': Fish,
  'gluten-free': Gluten,
  'lupine-free': Lupine,
  'mustard-free': Mustard,
  'peanut-free': Peanut,
  'sesame-free': Sesame,
  'shellfish-free': SeaFood,
  'soy-free': Soy,
  'tree-nut-free': Nuts,
  'wheat-free': Gluten,
  ovolactovegetarian: Ovolactovegeratian,
  vegetarian: Vegetarian,
  vegan: Vegan,
  flexitaria: Flexitaria,
  kosher: Kosher,
  halal: Halal,
  hindu: Hindu,
  'low-mercury': LowMercury,
  'low-sodium': LowSodium,
  keto: Keto,
  american: American,
  argentine: Argentine,
  chinese: Chinese,
  french: French,
  greek: Greek,
  indian: Indian,
  italian: Italian,
  japanese: Japanese,
  mexican: Mexican,
  moroccan: Moroccan,
  peruvian: Peruvian,
  spanish: Spanish,
  thai: Thai,
};

const CheckBoxSelect = ({ selection, onChange = null, options = [] }) => {
  const handleOptionSelected = (value) => {
    var currentSelection = selection.slice();
    if (!currentSelection.includes(value)) {
      currentSelection.push(value);
    } else {
      currentSelection.splice(currentSelection.indexOf(value), 1);
    }
    if (onChange != null) {
      onChange(currentSelection);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-wrap gap-4">
          {options.map((option) => {
            const { value, label, param } = option;
            console.log(param, 'param');
            return (
              <ButtonWithImage
                key={value}
                text={label}
                image={IMAGE_MAP[param]}
                data-value={value}
                selected={selection.includes(value)}
                onClick={() => handleOptionSelected(value)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckBoxSelect;
