import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from './ui/Card';
import FoodCategory from "../api/services/FoodCategory";
import Recipe from "../api/services/Recipe";
import Ingredient from "../api/services/Ingredient";
import User from "../api/services/User";
import FoodCategoryTile from "./ui/FoodCategoryTile";
import ReadMoreTile from "./ui/ReadMoreTile";
import UserTile from "./ui/UserTile";
import RecipeTile from "./ui/RecipeTile";
import RecipeSmallTile from "./ui/RecipeSmallTile";
import recipesOfMonthImg from '../img/month-recipes.jpg'; 
import MeasureInput from './forms/MeasureInput';
import PrimaryButton from './ui/PrimaryButton';

const Recipes = () => {
  const navigate = useNavigate();
  const [foodCategories, setFoodCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [buddyMakerUsers, setBuddyMakerUsers] = useState([]);
  const [recomendedRecipes, setRecomendedRecipes] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [ingredientSearch, setIngredientSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [openFilter, setOpenFilter] = useState("");
  const [caloriesInput, setCaloriesInput] = useState('');
  const [ingredientPage, setIngredientPage] = useState(0);
  const [categoryPage, setCategoryPage] = useState(0);

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    getFoodCategories();
    getIngredients();
    getBuddyMakerUsers();
    getRecomendedRecipes();
  }, []);

  const getFoodCategories = async () => {
    try {
      const response = await FoodCategory.list();
      setFoodCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getIngredients = async () => {
    try {
      const response = await Ingredient.list();
      setIngredients(response.data);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
    }
  };

  const updateRecipes = async (updatedParams) => {
    try {
      const params = {
        search,
        calories: caloriesInput,
        categories: selectedCategories.map(c => c.id),
        ingredients: selectedIngredients.map(i => i.id),
        ...updatedParams,
      };

      const response = await Recipe.list(params);
      setNoResults(response.data.length === 0);
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const getRecomendedRecipes = async () => {
    try {
      const response = await Recipe.list({ limit: 6 });
      setRecomendedRecipes(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching recommended recipes:', error);
    }
  };

  const getBuddyMakerUsers = async () => {
    try {
      const response = await User.listBuddMakers();
      setBuddyMakerUsers(response.data);
    } catch (error) {
      console.error('Error fetching buddy maker users:', error);
    }
  };

  const handleResetSearch = () => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    setRecipes([]);
    setNoResults(false);
  };

  const toggleFilter = (filter) => {
    setOpenFilter(openFilter === filter ? "" : filter);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    updateRecipes({ search: value });
  };

  const handleFilterClick = () => {
    updateRecipes({ calories: caloriesInput, categories: selectedCategories.map(c => c.id), ingredients: selectedIngredients.map(i => i.id) });
  };

  const handleCategorySearchChange = (e) => {
    setCategorySearch(e.target.value);
    setCategoryPage(0); // Reset category page when search changes
  };

  const handleIngredientSearchChange = (e) => {
    setIngredientSearch(e.target.value);
    setIngredientPage(0); // Reset ingredient page when search changes
  };

  const handleCategorySelect = (category) => {
    if (!selectedCategories.some(c => c.id === category.id)) {
      setSelectedCategories([...selectedCategories, category]);
    }
  };
  
  const handleIngredientSelect = (ingredient) => {
    if (!selectedIngredients.some(i => i.id === ingredient.id)) {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };

  const handleRemoveCategory = (category) => {
    setSelectedCategories(selectedCategories.filter(c => c.id !== category.id));
  };
  
  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(selectedIngredients.filter(i => i.id !== ingredient.id));
  };

  const handleOnChangeCaloriesInput = (value) => {
    setCaloriesInput(value);
  };

  const loadMoreIngredients = () => {
    setIngredientPage(prev => prev + 1);
  };

  const loadMoreCategories = () => {
    setCategoryPage(prev => prev + 1);
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-5'>
        <div className='w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-6'>
          <div>
            <div className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
              <button onClick={() => navigate('/', { replace: true })} className='text-title w-10 h-10'>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </div>
          </div>
          <div className='grow text-center'>
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                <div className='w-8 ps-4'>
                  <FontAwesomeIcon className='text-buddy-text-3' icon={faSearch} />
                </div>
              </div>
              <input type="text" onChange={handleSearchChange} autoComplete="off" className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl" name="search" placeholder="Buscador de recetas" />
              <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                <button type='button' onClick={handleResetSearch} className='flex items-center justify-center w-8 me-2 h-full'>
                  <div className='w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center'>
                    <FontAwesomeIcon className='text-white' icon={faTimes} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <div className="flex justify-between gap-2 mb-2">
            <div 
              onClick={() => toggleFilter("Ingredientes")}
              style={{ backgroundColor: "#394a53", flexGrow: 1, height: '32px' }} 
              className="rounded-lg text-sm text-center font-medium text-buddy-text-1 py-2 px-3 cursor-pointer"
            >
              Ingredientes
            </div>
            <div 
              onClick={() => toggleFilter("Tipo de comida")}
              style={{ backgroundColor: "#394a53", flexGrow: 1, height: '32px' }} 
              className="rounded-lg text-sm text-center font-medium text-buddy-text-1 py-2 px-3 cursor-pointer"
            >
              Tipo de comida
            </div>
            <div 
              onClick={() => toggleFilter("Calorías")}
              style={{ backgroundColor: "#394a53", flexGrow: 1, height: '32px' }} 
              className="rounded-lg text-sm text-center font-medium text-buddy-text-1 py-2 px-3 cursor-pointer"
            >
              Calorías
            </div>
          </div>

          {openFilter && (
            <div className="flex justify-between gap-2 mb-2 p-4 rounded-lg bg-buddy-bg-2">
              {openFilter === "Ingredientes" && 
                <div className='flex flex-col w-full gap-3'>
                  <span className="text-primary text-center text-sm font-medium">Busca y selecciona los ingredientes</span>
                  <input 
                    type="text" 
                    value={ingredientSearch} 
                    onChange={handleIngredientSearchChange} 
                    className="w-full p-2 bg-white rounded mb-2" 
                    placeholder="Buscar ingrediente"
                  />
                  <div className="flex flex-wrap gap-2">
                    {selectedIngredients.map(ingredient => (
                      <div key={ingredient.id} className="flex items-center px-3 py-1 bg-white rounded cursor-pointer">
                        {ingredient.name}
                        <div onClick={() => handleRemoveIngredient(ingredient)} style={{backgroundColor: 'rgb(255 140 0)'}} className="flex items-center justify-center ml-2 rounded-md p-1">
                          <FontAwesomeIcon icon={faTimes} className="text-white text-xs" />
                        </div>
                      </div>
                    ))}
                    {ingredients
                      .filter(i => i.name.toLowerCase().includes(ingredientSearch.toLowerCase()))
                      .slice(0, ITEMS_PER_PAGE * (ingredientPage + 1))
                      .map(ingredient => (
                        <div
                          key={ingredient.id}
                          onClick={() => handleIngredientSelect(ingredient)}
                          style={{
                            backgroundColor: "#424E55",
                            color: "white"
                          }}
                          className="flex items-center px-3 py-1 rounded cursor-pointer"
                        >
                          {ingredient.name}
                        </div>
                      ))}
                  </div>
                  {ingredients.filter(i => i.name.toLowerCase().includes(ingredientSearch.toLowerCase())).length > ITEMS_PER_PAGE * (ingredientPage + 1) && (
                    <button onClick={loadMoreIngredients} className="text-primary text-sm font-medium">
                      Cargar más
                    </button>
                  )}
                  <PrimaryButton type="button" onClick={handleFilterClick}>Filtrar</PrimaryButton>
                </div>
              }
              {openFilter === "Tipo de comida" && 
                <div className='flex flex-col w-full gap-3'>
                  <span className="text-primary text-center text-sm font-medium">Busca y selecciona las categorías</span>
                  <input 
                    type="text" 
                    value={categorySearch} 
                    onChange={handleCategorySearchChange} 
                    className="w-full p-2 bg-white rounded mb-2" 
                    placeholder="Buscar categoría"
                  />
                  <div className="flex flex-wrap gap-2">
                    {selectedCategories.map(category => (
                      <div key={category.id} className="flex items-center px-3 py-1 bg-white rounded cursor-pointer">
                        {category.name}
                        <div onClick={() => handleRemoveCategory(category)} style={{backgroundColor: 'rgb(255 140 0)'}} className="flex items-center justify-center ml-2 rounded-md p-1">
                          <FontAwesomeIcon icon={faTimes} className="text-white text-xs" />
                        </div>
                      </div>
                    ))}
                    {foodCategories
                      .filter(c => c.name.toLowerCase().includes(categorySearch.toLowerCase()))
                      .slice(0, ITEMS_PER_PAGE * (categoryPage + 1))
                      .map(category => (
                        <div 
                          key={category.id}
                          onClick={() => handleCategorySelect(category)}
                          style={{
                            backgroundColor: "#424E55",
                            color: "white"
                          }}
                          className="flex items-center px-3 py-1 rounded cursor-pointer"
                        >
                          {category.name}
                        </div>
                      ))}
                  </div>
                  {foodCategories.filter(c => c.name.toLowerCase().includes(categorySearch.toLowerCase())).length > ITEMS_PER_PAGE * (categoryPage + 1) && (
                    <button onClick={loadMoreCategories} className="text-primary text-sm font-medium">
                      Cargar más
                    </button>
                  )}
                  <PrimaryButton type="button" onClick={handleFilterClick}>Filtrar</PrimaryButton>
                </div>
              }
              {openFilter === "Calorías" && 
                <div className='flex flex-col w-100 gap-3'>
                  <span className="text-primary text-center text-sm font-medium">Selecciona el máximo de calorías que quieras que tenga tu plato</span>
                  <div className='flex justify-center py-4 px-6'>
                    <MeasureInput id="caloriesInput" value={caloriesInput} name="caloriesInput" type="number" onChange={handleOnChangeCaloriesInput} />
                  </div>
                  <PrimaryButton type="button" onClick={handleFilterClick}>Filtrar</PrimaryButton>
                </div>
              }
            </div>
          )}

          {recipes.length > 0 ? (
            <div className="mx-auto w-full">
              <div className="grid grid-cols-1 gap-3 mt-2">
                {recipes.map((recipe, i) => (
                  <Card key={i} className="bg-buddy-bg-2">
                    <RecipeTile recipe={recipe} key={recipe.id} to={`/recipe?id=${recipe.id}`} />
                  </Card>
                ))}
              </div>
            </div>
          ) : (
            noResults && search.length > 0 ? (
              <p className="text-buddy-text-1 font-semibold">No se encontraron recetas que cumplan con los criterios de búsqueda.</p>
            ) : (
              <>
                <div className="flex relative items-center w-full bg-opacity-80 rounded-2xl bg-black h-28 overflow-hidden">
                  <img src={recipesOfMonthImg} className="w-full aspect-1 opacity-60 rounded-2xl cent" />
                  <div className="w-full text-center absolute text-buddy-text-1 font-semibold">Recetas del mes</div>
                </div>

                <Card className="bg-buddy-bg-2">
                  <div className="flex justify-between items-center">
                    <h2 className="text-buddy-text-1 text-center font-semibold text-lg">BuddyMakers</h2>
                    <Link to="/buddyMakerList" className="text-primary text-center text-sm font-medium bg-buddy-view-all rounded-lg py-1 px-3 cursor-pointer">Ver todos</Link>
                  </div>
                  <div className="mb-0">
                    <div className="mx-auto w-full">
                      <div className="flex flex-no-wrap overflow-x-auto scrolling-touch mt-4">
                        {buddyMakerUsers.map(user => (
                          <UserTile user={user} key={user.id} to={`/recipesSearch?user_id=${user.id}`} />
                        ))}
                        {buddyMakerUsers.length === 10 && <ReadMoreTile to="/buddyMakerList" />}
                      </div>
                    </div>
                  </div>
                </Card>

                <Card className="bg-buddy-bg-2">
                  <div className="flex justify-between items-center">
                    <h2 className="text-buddy-text-1 text-center font-semibold text-lg">Categorías principales</h2>
                    <span onClick={() => navigate('/categories')} className="text-primary text-center text-sm font-medium bg-buddy-view-all rounded-lg py-1 px-3 cursor-pointer">Ver todas</span>
                  </div>
                  <div className="mb-0">
                    <div className="mx-auto w-full">
                      <div className="flex flex-no-wrap overflow-x-auto scrolling-touch mt-4">
                        {foodCategories.map(foodCategory => (
                          <FoodCategoryTile foodCategory={foodCategory} key={foodCategory.id} to={`/recipesSearch?food_category_id=${foodCategory.id}`} />
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>

                <Card className="bg-buddy-bg-2">
                  <div className="flex justify-between items-center">
                    <h2 className="text-buddy-text-1 text-center font-semibold text-lg">Recetas más populares</h2>
                    <Link to="/recomendedRecipes" className="text-primary text-center text-sm font-medium bg-buddy-view-all rounded-lg py-1 px-3 cursor-pointer">Ver todas</Link>
                  </div>
                  <div className="mb-0">
                    <div className="mb-0 mx-auto w-full">
                      <div className="grid grid-cols-3 gap-4 overflow-x-auto scrolling-touch mt-4" style={{ gridTemplateRows: "repeat(2, 1fr)" }}>
                        {recomendedRecipes.map(recipe => (
                          <RecipeSmallTile recipe={recipe} key={recipe.id} to={`/recipe?id=${recipe.id}`} />
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Recipes;