import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faSearch, faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from "../api/services/User.js";
import BuddyCard from './ui/BuddyCard.js';

const BuddyMakerList = () => {
  const navigate = useNavigate();
  const [buddyMakerUsers, setBuddyMakerUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    getBuddyMakerUsers();
  }, []);

  useEffect(() => {
    const to = setTimeout(() => {
      getBuddyMakerUsers();
    }, 500);
    return () => clearTimeout(to);
  }, [search]);

  const getBuddyMakerUsers = async () => {
    try {
      const response = await User.list({
        search: search,
        role_id: 5
      });
      setBuddyMakerUsers(response.data);
      setNoResults(response.data.length === 0);
    } catch (error) {
      console.error('Error fetching buddymaker users:', error);
    }
  };

  const handleResetSearch = () => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    getBuddyMakerUsers();
    setNoResults(false);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-5'>
        <div className='w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-6'>
          <div>
            <div className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
              <button onClick={() => navigate('/', { replace: true })} className='text-title w-10 h-10'>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </div>
          </div>
          <div className='grow text-center'>
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                <div className='w-8 ps-4'>
                  <FontAwesomeIcon className='text-buddy-text-3' icon={faSearch} />
                </div>
              </div>
              <input type="text" value={search} onChange={handleSearchChange} autoComplete="off" className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl" name="search" placeholder="Buscar BuddyMaker" />
              <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                <button type='button' onClick={handleResetSearch} className='flex items-center justify-center w-8 me-2 h-full'>
                  <div className='w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center'>
                    <FontAwesomeIcon className='text-white' icon={faTimes} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          {buddyMakerUsers.map((user) => (
            <BuddyCard user={user} key={user.id} />
          ))}
          {noResults && search && (
            <p className="text-buddy-text-1 font-semibold">No se encontraron BuddyMakers que cumplan con los criterios de búsqueda.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuddyMakerList;
