import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Toolbar = ({ children, to = '', replace = false, onClick = null }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(to, { replace: replace });
    }
  };

  return (
    <div className='w-full h-12 flex justify-start text-title items-center text-lg mb-2'>
      <div className='w-10 h-10 rounded-full absolute bg-white bg-opacity-20 flex flex-col justify-center items-center'>
        <button onClick={handleOnClick} className='text-title w-10 h-10'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className='grow text-center'>
        <div className='font-bold'>{children}</div>
      </div>
    </div>
  );
};

export default Toolbar;