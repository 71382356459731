import React, {useState, useEffect} from 'react'
import Recipe from '../../api/services/Recipe';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClockIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline';
import Badge from './Badge';

const RecipeSmallTile = ({ recipe, to = null, showStatus = false, showAuthor = true }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        /*if (!recipe.image_url) {
            setLoading(true);
            getImage();
        }*/
        
    }, []);
    
    const getImage = async () => {
        try {
            const response = await Recipe.image(recipe.id);
            const blob = await response.blob();
            setImg(URL.createObjectURL(blob));
        } catch (error) {
            console.error('Error fetching recipes:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
            <Link to={to} className="w-full *:relative flex-none mb-2 rounded-lg">
                <div className="relative">
                    <div className="w-full overflow-hidden rounded-lg bg-black">
                        {
                            loading ?
                            <div className="bg-black h-28 w-28 object-cover flex items-center justify-center"><FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' /></div>
                            :
                            <img src={recipe.image_url} alt="" className="bg-black brightness-50 w-full h-full object-cover object-center" />
                        }
                    </div>
                    <div className='absolute bottom-3 w-full text-center text-buddy-text-1 text-xs'>{recipe.name}</div>
                </div>
               
            </Link>
    )
}

export default RecipeSmallTile 