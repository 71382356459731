import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import CommunicationChannel from '../api/services/CommunicationChannel';

const ProfileForm6 = () => {
  const navigate = useNavigate();

  const doSportsOptions = [
    {
      value: 1,
      label: 'Sí, practico deporte/actividad física regularmente.',
    },
    {
      value: 0,
      label: 'No, no practico deporte/actividad física regularmente.',
    },
  ];

  const [doSports, setDoSports] = useState('');
  const [communicationChannelId, setCommunicationChannelId] = useState('');
  const [communicationChannelOptions, setCommunicationChannelOptions] = useState([]);

  const handleClick = (e) => {
    if (doSports !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.practice_sports_or_physical_activity = doSports;
      data.communication_channel_id = communicationChannelId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm7');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  }

  const handleOnChangeDoSports = (value) => {
    setDoSports(value);
  }

  const handleOnChangeCommunicationChannelId = (value) => {
    setCommunicationChannelId(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    loadCommunicationChannels();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setDoSports(data.practice_sports_or_physical_activity ?? '');
      setCommunicationChannelId(data.communication_channel_id ?? ''); 
    }
  }, []);

  async function loadCommunicationChannels() {
    try {
      const response = await CommunicationChannel.list();
      var options = [];
      response.data.map(channel => {
        options.push({
          value: channel.id,
          label: channel.name
        });
      });
      setCommunicationChannelOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm5"} replace={true}>
              <div className='text-sm'>
                <span >Paso 6</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Practicas algún deporte o actividad física regularmente?</h2>
            <SelectList onChange={handleOnChangeDoSports} selected={doSports} options={doSportsOptions} />
            <div className='my-2'></div>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cómo quieres que nos comuniquemos contigo? <span className='text-primary'>(opcional)</span></h2>
            <SelectList onChange={handleOnChangeCommunicationChannelId} selected={communicationChannelId} options={communicationChannelOptions} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm6;