import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const ProfileForm5 = () => {
  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  const [healthySubstitutes, setHealthySubstitutes] = useState('');
  const [cheapSubstitutes, setCheapSubstitutes] = useState('');

  const handleClick = (e) => {
    if (healthySubstitutes !== '' && cheapSubstitutes !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.suggest_healthier_products = healthySubstitutes;
      data.suggest_cheaper_products = cheapSubstitutes;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm6');
    } else {
        alert('Responda a las preguntas para continuar.');
    }
  }

  const handleHealthySubstitutes = (value) => {
    setHealthySubstitutes(value);
  }

  const handleCheapSubstitutes = (value) => {
    setCheapSubstitutes(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setHealthySubstitutes(data.suggest_healthier_products ?? '');
      setCheapSubstitutes(data.suggest_cheaper_products ?? '');
    }
  }, []);

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm4"} replace={true}>
              <div className='text-sm'>
                <span >Paso 5</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Estás interesado en recibir sugerencias de productos sustitutos más económicos?</h2>
            <SelectList onChange={handleHealthySubstitutes} selected={healthySubstitutes} options={options} />
            <div className='my-2'></div>
            <h2 className='w-full text-white text-xl font-semibold'>¿Estás interesado en recibir sugerencias de productos sustitutos más saludables?</h2>
            <SelectList onChange={handleCheapSubstitutes} selected={cheapSubstitutes} options={options} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm5;