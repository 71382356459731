import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import CheckIcon from './checkIcon';

const ProfileForm28 = () => {
  const [plan, setPlan] = useState();
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate('/signUpConfirm');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPlan(JSON.parse(localStorage.getItem('plan')));
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/ProfileFormPremiumPlan'} replace={true}>
              <div className="text-sm capitalize">
                <span>Plan {plan?.type}</span>
              </div>
            </Toolbar>

            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col bg-buddy-bg-2 text-white p-4 rounded-2xl gap-4">
                <div className="flex justify-between items-center">
                  <div className="text-primary font-medium">
                    Plan {plan?.type}
                  </div>
                  <div className="capitalize text-sm">
                    {plan?.price}€
                    <span className="text-body">/{plan?.type}</span>
                  </div>
                </div>
                <div className="border border-buddy-bg-3" />
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <CheckIcon className="w-6 h-6" />
                    <div>Experiencia sin publicidad</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckIcon className="w-6 h-6" />
                    <div>Soporte prioritario 24/7</div>
                  </div>
                </div>
              </div>
            </div>

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Pagar
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm28;
