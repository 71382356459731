import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
const ProfileForm3 = () => {
  const navigate = useNavigate();

  const organicProductOptions = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
    {
      value: -1,
      label: 'Indiferente',
    }
  ];

  const receiveOffersOptions = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    }
  ];

  const [organicProducts, setOrganicProducts] = useState('');
  const [receiveOffers, setReceiveOffers] = useState('');

  const handleClick = (e) => {
    if (organicProducts !== '' && receiveOffers !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.organic_products = organicProducts;
      data.receive_offers_and_discounts = receiveOffers;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm4');
    } else {
      alert('Responda a las preguntas para continuar.');
    }
  }

  const handleOnChangeOrganicProducts = (value) => {
    setOrganicProducts(value);
  }

  const handleOnChangeReceiveOffer = (value) => {
    setReceiveOffers(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setOrganicProducts(data.organic_products ?? '');
      setReceiveOffers(data.receive_offers_and_discounts ?? '');
    }
  }, []);

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar  to={"/profileForm2"} replace={true}>
              <div className='text-sm'>
                <span >Paso 3</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Prefieres productos orgánicos?</h2>
            <SelectList onChange={handleOnChangeOrganicProducts} selected={organicProducts} options={organicProductOptions} />
            <div className='my-2'></div>
            <h2 className='w-full text-white text-xl font-semibold'>¿Deseas recibir ofertas especiales y descuentos?</h2>
            <SelectList onChange={handleOnChangeReceiveOffer} selected={receiveOffers} options={receiveOffersOptions} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm3;