import client from "../client";

export default {
    status: () => client.get("subscription/status").json(),
    createCheckoutSession: (body) => client.post(`subscription/create-checkout-session`, {body: body}).json(),
    createSubscription: (body) => client.post(`subscription/create-subscription`, {body: body}).json(),
    addCard: (body) => client.post(`subscription/add-card`, {body: body}).json(),
    listCards: () => client.get("subscription/list-cards").json(),
    deleteCard: (cardID) => client.delete(`subscription/delete-card/${cardID}`).json(),
    cancelSubscription: () => client.post(`subscription/cancel`,).json(),
};