import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const BuddyRecipeCard = ({ recipe, to = '' }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Link className='w-full' to={ "/recipe?id=" + recipe.id }>
                <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                    <div className="relative w-16 shrink-0">
                        <div className='relative'>
                            {
                                loading ?
                                <div className="bg-black h-16 w-16 object-cover flex items-center justify-center"><FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' /></div>
                                :
                                <img src={recipe.image_url} alt="" className="h-14 w-14 rounded-md object-cover object-center" />
                            }
                        </div>
                    </div>
                  <div className='flex flex-col justify-center grow pl-5 text-start'>
                    <div style={{ fontSize: '17px',fontWeight: '500' }} className="text-white">{ recipe.name }</div>
                  </div>
                  <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0 mt-auto mb-auto' />
                </div>
            </Link>
        </>
    )
}

export default BuddyRecipeCard 