import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import clsx from 'clsx';

const ProfileForm28 = () => {
  const [plan, setPlan] = useState();
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate('/ProfileFormPremiumPayment');
    localStorage.setItem('plan', JSON.stringify(plan));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlan = (plan) => {
    setPlan(plan);
  };

  console.log(plan);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="mih-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col justify-between gap-3">
            <Toolbar to={'/profileForm9'} replace={true}>
              <div className="text-sm">
                <span>Elegir plan de BuddyMarket</span>
              </div>
            </Toolbar>

            <div>
              <div className="w-full flex flex-col gap-4">
                <div
                  className={clsx(
                    'w-full flex flex-col justify-between bg-buddy-bg-2 rounded-2xl text-white p-4 gap-2 cursor-pointer border-2',
                    {
                      'border-primary': plan?.type === 'mensual',
                      'border-transparent': plan?.type !== 'mensual',
                    },
                  )}
                  onClick={() => handlePlan({ type: 'mensual', price: '5,99' })}
                >
                  <div className="text-primary font-medium">Mensual</div>
                  <div className="flex justify-between font-normal">
                    <div>
                      Se facturará una vez expirado el periodo de prueba (7
                      días)
                    </div>
                  </div>
                  <div className="text-body text-sm">Después 5,99 € / mes</div>
                </div>
                <div
                  className={clsx(
                    'w-full flex flex-col justify-between bg-buddy-bg-2 rounded-2xl text-white p-4 gap-2 cursor-pointer border-2',
                    {
                      'border-primary': plan?.type === 'trimestral',
                      'border-transparent': plan?.type !== 'trimestral',
                    },
                  )}
                  onClick={() =>
                    handlePlan({ type: 'trimestral', price: '17,97' })
                  }
                >
                  <div className="text-primary font-medium">Trimestral</div>
                  <div className="flex justify-between font-normal">
                    <div>7 días de prueba</div>
                    <div className="bg-primary px-2 py-1 rounded">
                      Ahorra 10%
                    </div>
                  </div>
                  <div className="text-body text-sm">Después 17,97 € / mes</div>
                </div>
                <div
                  className={clsx(
                    'w-full flex flex-col justify-between bg-buddy-bg-2 rounded-2xl text-white p-4 gap-2 cursor-pointer border-2',
                    {
                      'border-primary': plan?.type === 'anual',
                      'border-transparent': plan?.type !== 'anual',
                    },
                  )}
                  onClick={() => handlePlan({ type: 'anual', price: '71,88' })}
                >
                  <div className="text-primary font-medium">Anual</div>
                  <div className="flex justify-between font-normal">
                    <div>7 días de prueba</div>
                    <div className="bg-primary px-2 py-1 rounded">
                      Ahorra 20%
                    </div>
                  </div>
                  <div className="text-body text-sm">Después 71,88 € / mes</div>
                </div>
              </div>

              <FooterToolbar>
                <PrimaryButton
                  type="button"
                  onClick={handleClick}
                  disabled={!plan?.type}
                >
                  Seleccionar
                </PrimaryButton>
              </FooterToolbar>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm28;
