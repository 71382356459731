import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon, EnvelopeIcon, UsersIcon  } from '@heroicons/react/24/outline'

const Security = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user);

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={'/sidebarMenu'}>
            <div className='text-md'>Seguridad</div>
          </Toolbar>

          <Link className='w-full' to={'/userUpdatePassword'}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <LockClosedIcon className='size-6 text-buddy-text-1 grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Cambiar contraseña</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <Link to={'/userUpdateEmail'} className='w-full'>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <EnvelopeIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Cambiar correo electrónico</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <Link to={'/userRevokeSocialAccess'} className='w-full'>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <UsersIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Revocar acceso a redes sociales</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Security;