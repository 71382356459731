import React, { useState, useEffect } from 'react';
import logobuddy from '../../img/b-de-buddy.png';
import bgLogin from '../../img/bg-login-2.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowRightCircle, BsEye, BsEyeSlash } from 'react-icons/bs';
import Card from '../ui/Card';
import Auth from '../../api/services/Auth';
import LoginInput from '../forms/LoginInput';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import LoginSeparatorRight from './assets/loginSeparatorRight';
import LoginSeparatorLeft from './assets/loginSeparatorLeft';
import AppleIcon from './assets/appleIcon';
import GoogleIcon from './assets/googleIcon';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Auth.login({ email, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      await getProfile();
      localStorage.setItem('register_data', null);
    } catch (error) {
      console.error(error);
      if (error.code === 403) {
        alert('Usuario o contraseña incorrecto');
      }
    }
  };

  const getProfile = async () => {
    try {
      const response = await Auth.profile();
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/');
    } catch (error) {
      if (error.code === 401) {
        navigate('login', { replace: true });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;

      const response = await Auth.loginGoogle({ token: credential });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      navigate('/');
    } catch (error) {
      alert('Ha ocurrido un error');
      console.error('Error logging in with Google:', error);
    }
  };

  const handleGoogleLoginFailure = () => {
    alert('Login failed, please try again.');
  };

  const handleAppleLoginSuccess = async (response) => {
    try {
      const { authorization } = response;
      const identityToken = authorization.id_token;
      const apiResponse = await Auth.loginApple({ token: identityToken });
      localStorage.setItem('token', apiResponse.data.token);
      localStorage.setItem('user', JSON.stringify(apiResponse.data.user));
      navigate('/');
    } catch (error) {
      alert('Ha ocurrido un error');
      console.error('Error logging in with Apple:', error);
    }
  };

  const handleAppleLoginFailure = (error) => {
    console.error('Error with Apple Sign-In:', error);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const handleShowPassword = () => {
    setIsPasswordHidden((prev) => !prev);
  };

  const bgImageStyle = {
    backgroundImage: `url(${bgLogin})`,
  };

  return (
    <div
      className="min-h-screen bg-no-repeat bg-cover bg-center"
      style={bgImageStyle}
    >
      <div className="flex flex-col min-h-screen bg-new-bg bg-opacity-60 p-6 gap-6">
        {/* Header */}
        <div className="flex flex-col items-center">
          <img className="w-2/5 z-10" src={logobuddy} alt="Logo BuddyMarket" />
          <Card className="flex flex-col -mt-12 p-6 pb-8 pt-12 bg-new-bg text-white text-center gap-3">
            <h1 className="font-title text-2xl text-white font-bold">
              Bienvenid@ a BuddyMarket
            </h1>
            <p className="text-sm text-center text-body">
              Donde tus gustos y nuestra inteligencia artificial cocinan juntos
              la lista de compras perfecta.
            </p>
          </Card>
        </div>

        {/* Login Form */}
        <form className="flex flex-col items-center" onSubmit={handleSubmit}>
          <div className="w-full flex flex-col gap-4">
            <LoginInput
              label="Usuario"
              type="text"
              name="email"
              placeholder="Correo electrónico"
              onChange={(e) => setEmail(e.target.value)}
              suffixIcon={<BsArrowRightCircle className="text-white w-6 h-6" />}
            />

            <LoginInput
              label="Contraseña"
              type={isPasswordHidden ? 'password' : 'text'}
              name="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              suffixIcon={
                isPasswordHidden ? (
                  <BsEyeSlash
                    className="text-white w-6 h-6 cursor-pointer"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <BsEye
                    className="text-white w-6 h-6 cursor-pointer"
                    onClick={handleShowPassword}
                  />
                )
              }
            />

            <Link className="text-primary text-right" to="/resetpassword">
              ¿Has olvidado tu contraseña?
            </Link>

            <button
              type="submit"
              className="bg-new-primary-button font-bold text-white rounded-lg p-4"
            >
              Iniciar sesión
            </button>
          </div>
        </form>

        {/* Separator line */}
        <div className="flex justify-center items-center gap-2">
          <LoginSeparatorLeft />
          <div className="w-2 h-2 rounded-full border-2 border-white" />
          <LoginSeparatorRight />
        </div>

        {/* Social Login */}
        <div className="flex flex-col items-center gap-3">
          {/* Apple Login */}
          <AppleSignin
            authOptions={{
              clientId: 'io.dummy.buddymarket',
              scope: 'email',
              redirectURI: 'https://dummy.buddymarket.io/login',
              state: 'state',
              nonce: 'nonce',
              usePopup: true,
            }}
            onSuccess={handleAppleLoginSuccess}
            onError={handleAppleLoginFailure}
            render={(props) => (
              <button
                {...props}
                className="flex justify-center items-center gap-2 bg-black text-white w-full p-4 rounded-lg font-bold"
              >
                <AppleIcon className="w-6 h-6" />
                Continuar con Apple
              </button>
            )}
          />

          {/* Google Login */}
          <button
            className="flex justify-center items-center gap-2 bg-white text-black w-full p-4 rounded-lg font-bold"
            onClick={handleGoogleLogin}
          >
            <GoogleIcon className="w-6 h-6" />
            Continuar con Google
          </button>
        </div>

        <div className="text-sm text-white text-center">
          ¿No tienes cuenta?{' '}
          <Link className="text-primary font-bold" to="/SignUp">
            Crear una cuenta
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
