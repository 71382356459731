import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Input = ({
  value: propValue,
  name,
  label = null,
  type = 'text',
  placeholder = '',
  helpText = '',
  icon = null,
  onChange,
  onReset = null,
  disabled = false,
  typePassword = false,
}) => {
  const [internalValue, setInternalValue] = useState(propValue ?? '');
  const inputReference = useRef(null);

  // Update internal value when prop value changes
  useEffect(() => {
    if (propValue !== undefined) {
      setInternalValue(propValue);
    }
  }, [propValue]);

  const resetButtonClassName =
    internalValue?.toString().length > 0 && !disabled
      ? 'absolute right-0 bottom-0 top-0'
      : 'hidden absolute right-0 bottom-0 top-0';

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    if (onChange) {
      onChange(e);
    }
  };

  const handleResetInput = () => {
    if (disabled) return;

    const event = {
      target: {
        name,
        value: '',
      },
    };

    setInternalValue('');
    if (onChange) {
      onChange(event);
    }
    if (onReset) {
      onReset();
    }
    inputReference.current.focus();
  };

  return (
    <div className="w-full">
      {label != null ? (
        <p className="text-white font-semibold text-md mb-2">{label}</p>
      ) : (
        ''
      )}

      <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
        {icon && (
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2 flex items-center justify-center h-full">
            <FontAwesomeIcon
              style={{ color: 'rgb(122, 132, 153)' }}
              icon={icon}
            />
          </div>
        )}
        {!disabled && !typePassword && (
          <div className={resetButtonClassName}>
            <button
              type="button"
              onClick={handleResetInput}
              className="flex items-center justify-center w-8 me-2 h-full"
            >
              <div className="w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center">
                <FontAwesomeIcon className="text-white" icon={faTimes} />
              </div>
            </button>
          </div>
        )}
        <input
          value={internalValue}
          onChange={handleChange}
          ref={inputReference}
          autoComplete="new-password"
          aria-autocomplete="none"
          list="autocompleteOff"
          className={`appearance-none w-full h-full bg-transparent text-white pe-16 placeholder:font-light rounded-xl ${
            icon ? 'pl-10' : 'pl-4'
          }`}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      <span className="text-body text-xs">{helpText}</span>
    </div>
  );
};

export default Input;
