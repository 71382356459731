import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Toggle from './forms/Toggle';
import MeasureInput from './forms/MeasureInput';
import UserWeightType from '../api/services/UserWeightType';

const ProfileForm20 = () => {
  const navigate = useNavigate();

  const [weightType, setWeightType] = useState('');
  const [weightTypeOptions, setWeightTypeOptions] = useState([]);
  const [weight, setWeight] = useState('');
 
  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.target_weight = weight;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm21');
  }

  const handleOnChangeWeight = (value) => {
    setWeight(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    loadWeightTypeOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setWeight(data.target_weight ?? '');
    }
  }, []);

  async function loadWeightTypeOptions() {
    try {
      const data = JSON.parse(localStorage.getItem('register_data'));
      const response = await UserWeightType.list();
      var options = [];
      response.data.map(type => {
        options.push({
          value: type.id,
          label: type.name
        });
        if (type.id == data.user_weight_type_id) {
          setWeightType(type.name);
        }
      });
      setWeightTypeOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm19"} replace={true}>
              <div className='text-sm'>
                <span >Paso 20</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Peso objetivo <span className='text-primary'>(opcional)</span></h2>
            <div className='w-2/3 mt-6'>
              <div className='w-full'>
                <div className="w-full flex bg-buddy-bg-3 rounded-xl p-3 gap-3">
                <button type='button' className="w-full text-buddy-text-1 text-center py-5 px-4 text-md rounded-xl font-semibold bg-buddy-bg-4">{ weightType }</button>
                </div>
              </div>
            </div>
            <div className='my-2'></div>
            <MeasureInput id="weight" value={weight} onChange={handleOnChangeWeight} name="weight" type="number"></MeasureInput>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm20;