import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import instagramIcon from '../icons/instagram.png';
import linkedinIcon from '../icons/linkedin.png';
import youtubeIcon from '../icons/youtube.png';
import User from "../api/services/User.js";

const UserRevokeSocialAccess = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
        gender: 'Hombre',
        instagram: '',
        linkedin: '',
        youtube: ''
    });

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        try {
            const response = await User.profile();
            setProfile({
                name: response.data.name,
                email: response.data.email,
                phone: response.data.phone,
                description: response.data.description || '',
                gender: response.data.gender.name,
                instagram: response.data.instagram,
                linkedin: response.data.linkedin,
                youtube: response.data.youtube
            });
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const revokeSocialAccess = (social) => {
        setProfile({
            ...profile,
            [social]: null
        });
    };

    const handleSave = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const genderId = profile.gender === 'Hombre' ? 1 : 2;
            const updatedProfile = { 
                ...profile,
                gender_id: genderId
            };
            await User.update(user.id, updatedProfile);
            const updatedUser = { ...user, name: updatedProfile.name };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            navigate('/security');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };
  
    const renderSocialRow = (platform, url, icon) => (
        <div className='w-full flex justify-start gap-1 bg-buddy-bg-2 p-3 rounded-2xl' onClick={() => revokeSocialAccess(platform)}>
            <img src={icon} alt={platform} />
            <div className='px-2 text-start'>
              <span className='text-buddy-text-1 mt-auto mb-auto font-semibold'>{url ?? ''}</span>
            </div>
        </div>
    );
  
    return (
      <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
        <div className='h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={'/security'}>Revocar Acceso Redes</Toolbar>
          <form className='h-full w-full mt-10'>
            <div className='flex flex-col flex-start items-center gap-3'>
                <h2 className='w-full text-left text-white text-sm font-medium'>Redes Sociales</h2>
                {renderSocialRow('instagram', profile.instagram, instagramIcon)}
                {renderSocialRow('linkedin', profile.linkedin, linkedinIcon)}
                {renderSocialRow('youtube', profile.youtube, youtubeIcon)}
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type='button' onClick={handleSave}>
              Guardar cambios
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    );
};

export default UserRevokeSocialAccess;