import React from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import PrimaryButton from './ui/PrimaryButton';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput';
import Input from './forms/Input';
import ConditionsCheckbox from './forms/ConditionsCheckbox';
import NewsletterCheckbox from './forms/NewsletterCheckbox';
import FooterToolbar from './ui/FooterToolbar';

const SignUp = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data')) ?? {};
    data.name = document.querySelector('[name="name"]').value;
    data.email = document.querySelector('[name="email"]').value;
    data.phone = '';
    data.password = document.querySelector('[name="password"]').value;
    data.password_repeat = document.querySelector(
      '[name="password_repeat"]',
    ).value;
    data.accept_terms = document.querySelector('[name="accept_terms"]:checked')
      ? 1
      : 0;
    data.accept_communications = document.querySelector(
      '[name="accept_communications"]:checked',
    )
      ? 1
      : 0;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm1');
  };

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <form className="w-full">
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to="/login">Registro</Toolbar>
            <Input name="name" label="Nombre completo" placeholder=""></Input>
            <Input
              name="email"
              type="email"
              label="Correo electrónico"
              placeholder=""
            ></Input>
            <PasswordWithValidationInput
              name="password"
              label="Contraseña"
              placeholder=""
              withValidation={true}
            ></PasswordWithValidationInput>
            <div className="w-full -mt-4">
              <Input
                name="password_repeat"
                type="password"
                label="Repetir contraseña"
                placeholder=""
              ></Input>
            </div>
            {false && (
              <Input
                name="phone"
                type="phone"
                label="Número de teléfono (opcional)"
                placeholder=""
                helpText="Ej: +34 684 93 49 34"
              ></Input>
            )}
            <ConditionsCheckbox name="accept_terms" />
            <NewsletterCheckbox name="accept_communications" />
          </div>
        </form>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default SignUp;
