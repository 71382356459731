import React, { useState, useEffect } from 'react';
import FoodCategory from '../../api/services/FoodCategory';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FoodCategoryTile = ({ foodCategory, to = '', width = '100px', height = '100px' }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Uncomment the next two lines to enable image fetching
        // setLoading(true);
        // getImage();
    }, []);
    
    const getImage = async () => {
        try {
            const response = await FoodCategory.image(foodCategory.id);
            const blob = await response.blob();
            setImg(URL.createObjectURL(blob));
        } catch (error) {
            console.error('Error fetching recipes:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Link to={to} style={{ height, width }} className="relative flex-none mr-3 mb-4 rounded-lg">
                <div className="relative h-full">
                    <div className="w-full overflow-hidden rounded-lg bg-black" style={{ height, width }}>
                        {
                            loading ?
                            <div className="bg-black h-full w-full object-cover flex items-center justify-center">
                                <FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' />
                            </div>
                            :
                            <img src={foodCategory.image_url} alt="" className="bg-black brightness-50 object-cover object-center" style={{ height, width }} />
                        }
                    </div>
                </div>
                <div className='absolute bottom-3 w-full text-center text-buddy-text-1 text-xs'>{foodCategory.name}</div>
            </Link>
        </>
    );
};

export default FoodCategoryTile;
