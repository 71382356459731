import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faChevronLeft, faL } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import UserShoppingList from '../../api/services/UserShoppingList.js';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import AdImage from './assets/ad.png';
import MercadonaLogo from './assets/mercadonaLogo.png';

const ShoppingListList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [shoppingLists, setShoppingLists] = useState([]);
  const [pastShoppingLists, setPastShoppingLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getShoppingLists();
    getPastShoppingLists();
  }, []);

  const getShoppingLists = async () => {
    setLoading(true);
    setShoppingLists([]);
    try {
      const response = await UserShoppingList.list(user.id, {});
      setShoppingLists(response.data);
    } catch (error) {
      console.error('Error fetching shoppinglists:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPastShoppingLists = async () => {
    setLoading(true);
    setPastShoppingLists([]);
    try {
      const response = await UserShoppingList.list(user.id, {
        old: true,
      });
      setPastShoppingLists(response.data);
    } catch (error) {
      console.error('Error fetching past shoppinglists:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col gap-6 h-full bg-secondary-bg bg-opacity-60 p-6">
        {showMenu && (
          <div
            onClick={(e) => {
              setShowMenu(false);
            }}
            className="absolute top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60"
          >
            <div className="mx-0 border-t border-buddy-bg-2 bg-secondary-bg p-4">
              <button
                onClick={(e) => {
                  navigate('/shoppingListCreate');
                }}
                className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
              >
                <div className="pointer-events-none">
                  <svg
                    className="pointer-events-none"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_544_3820)">
                      <path
                        d="M22.1667 15.1667H10.5V12.8333H22.1667V15.1667ZM22.1667 8.16667H14V10.5H22.1667V8.16667ZM10.5 19.8333H15.1667V17.5H10.5V19.8333ZM2.33333 10.5V6.8005C0.975333 6.31867 0 5.02133 0 3.5C0 1.57033 1.57033 0 3.5 0C4.06933 0 4.62583 0.144667 5.1275 0.4165C6.3035 -0.131833 7.6965 -0.131833 8.8725 0.4165C9.373 0.144667 9.93067 0 10.5 0C12.4297 0 14 1.57033 14 3.5C14 5.02133 13.0247 6.31867 11.6667 6.8005V10.5H2.33333ZM2.33333 3.5C2.33333 4.144 2.85717 4.66667 3.5 4.66667H4.66667V8.16667H9.33333V4.66667H10.5C11.1428 4.66667 11.6667 4.144 11.6667 3.5C11.6667 2.856 11.1428 2.33333 10.5 2.33333C10.1477 2.33333 9.88633 2.50717 9.72883 2.653L9.03 3.30283L8.25417 2.7475C7.497 2.205 6.50067 2.205 5.7435 2.7475L4.96767 3.30283L4.26883 2.653C4.1125 2.50717 3.85117 2.33333 3.49767 2.33333C2.85483 2.33333 2.331 2.856 2.331 3.5H2.33333ZM28 3.5V19.1497L19.1497 28H4.66667V12.8333H7V25.6667H17.5V17.5H25.6667V3.5C25.6667 2.856 25.144 2.33333 24.5 2.33333H16.3333V0H24.5C26.4332 0 28 1.56683 28 3.5ZM19.8333 24.017L24.017 19.8333H19.8333V24.017Z"
                        fill="#FF8C00"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_544_3820">
                        <rect width="28" height="28" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">
                  Crear Lista de la compra
                </span>
                <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
              </button>
            </div>
          </div>
        )}
        <div className="h-12 flex justify-start text-title items-center text-lg">
          <button
            onClick={(e) => {
              showMenu ? setShowMenu(false) : navigate('/', { replace: true });
            }}
            className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="grow text-center">
            <div className="font-bold ">Mis listas de la compra</div>
          </div>
          <button
            onClick={(e) => {
              setShowMenu(!showMenu);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_543_3313)">
                <rect x="6" width="4" height="4" rx="2" fill="white" />
                <rect x="6" y="6" width="4" height="4" rx="2" fill="white" />
                <rect x="6" y="12" width="4" height="4" rx="2" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_543_3313">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div className="flex flex-col gap-6">
          <Link to="/shoppingListCreate">
            <img src={AdImage} alt="ad" className="w-full" />
          </Link>
          <div className="font-title text-xl font-bold text-white">
            Supermercados
          </div>
          <Link to="/shoppingListShow?id=43" className="w-fit">
            <img src={MercadonaLogo} alt="mercadona" className="w-16 h-auto" />
            <div className="text-xs font-title font-semibold text-white">
              Mercadona
            </div>
          </Link>
          <div className="font-title text-xl font-bold text-white">
            Listas de la compra pendientes
          </div>
          <div className="grid grid-cols-1 gap-3">
            {shoppingLists.map((shoppingList) => {
              return (
                <Link
                  key={shoppingList.id}
                  to={'/shoppingListShow?id=' + shoppingList.id}
                >
                  <div className="bg-buddy-bg-2 px-3 py-3 flex justify-between items-center space-x-3  rounded-xl">
                    <div className="overflow-hidden rounded-lg bg-transparent">
                      <img
                        src={shoppingList.image_url}
                        alt=""
                        className="bg-transparent h-12 w-12 object-cover object-center"
                      />
                    </div>
                    <div className="flex flex-col grow">
                      <span className="text-buddy-text-1 font-medium">
                        {shoppingList.name}
                      </span>
                      <span className="text-buddy-text-6 text-sm">
                        {shoppingList.date_format}
                      </span>
                    </div>
                    <div className="px-2">
                      <ChevronRightIcon className="size-5 text-buddy-text-6"></ChevronRightIcon>
                    </div>
                  </div>
                </Link>
              );
            })}

            {shoppingLists.length == 0 && !loading && (
              <div className="text-buddy-text-2">
                No se encontraron listas de la compra pendientes
              </div>
            )}
          </div>
          <div className="text-buddy-text-1 text-lg font-semibold mt-6">
            Listas de la compra pasadas
          </div>
          <div className="grid grid-cols-1 gap-3 mt-2">
            {pastShoppingLists.map((shoppingList) => {
              return (
                <Link
                  key={shoppingList.id}
                  to={'/shoppingListShow?id=' + shoppingList.id}
                >
                  <div className="bg-buddy-bg-2 px-3 py-3 flex justify-between items-center space-x-3  rounded-xl">
                    <div className="overflow-hidden rounded-lg bg-transparent">
                      <img
                        src={shoppingList.image_url}
                        alt=""
                        className="bg-transparent h-12 w-12 object-cover object-center"
                      />
                    </div>
                    <div className="flex flex-col grow">
                      <span className="text-buddy-text-1 font-medium">
                        {shoppingList.name}
                      </span>
                      <span className="text-buddy-text-6 text-sm">
                        {shoppingList.date_format}
                      </span>
                    </div>
                    <div className="px-2">
                      <ChevronRightIcon className="size-5 text-buddy-text-6"></ChevronRightIcon>
                    </div>
                  </div>
                </Link>
              );
            })}

            {pastShoppingLists.length == 0 && !loading && (
              <div className="text-buddy-text-2">
                No se encontraron listas de la compra pasadas
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingListList;
