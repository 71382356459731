import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logoSecundary from "../../img/logo-secondary.png";

const SecondaryToolbar = ({ children, to = '', replace = false  }) => {

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(to, { replace: replace});
    }

    return (
        <>
            <div className='w-full h-12 flex justify-between text-lg mb-2'>
                <div className='w-10 h-10 rounded-full absolute bg-white bg-opacity-20 flex flex-col justify-center items-center'>
                    <button onClick={handleOnClick} className='text-title w-10 h-10'><FontAwesomeIcon icon={faChevronLeft} /></button>
                </div>
                <div className='flex justify-center items-center ml-auto'>
                    <img style={{'width':'200px'}} src={logoSecundary} />
                </div>
            </div>
        </>
    )
}

export default SecondaryToolbar 