import React, { useState, useEffect, useRef } from 'react';
import Chat from '../../api/services/Chat.js';
import { BsArrowLeft } from 'react-icons/bs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import buddyIcon from '../../img/buddyIcon.png';
import SendIcon from './assets/sendIcon.js';
import StarsIcon from './assets/starsIcon.js';

const BuddyChat = () => {
  const [messages, setMessages] = useState([
    {
      text: 'Pregúntame lo que quieras sobre nutrición, alimentación, etc',
      sender: 'bot',
    },
  ]);
  const [userInput, setUserInput] = useState('');
  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // Estado de carga
  const messagesEndRef = useRef(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim() || isLoading) return; // Evita enviar si está cargando

    const newMessages = [...messages, { text: userInput, sender: 'user' }];
    setMessages(newMessages);
    setUserInput('');
    setIsLoading(true); // Iniciar el estado de carga

    try {
      const body = new FormData();
      body.append('messages', JSON.stringify(newMessages));
      const response = await Chat.chat(body);
      if (response && response.answer) {
        setMessages([...newMessages, { text: response.answer, sender: 'bot' }]);
      } else {
        throw new Error('Invalid data structure');
      }
    } catch (error) {
      console.error('Error fetching API:', error);
      setMessages([
        ...newMessages,
        {
          text: 'Lo siento, hubo un error procesando tu solicitud.',
          sender: 'bot',
        },
      ]);
    } finally {
      setIsLoading(false); // Terminar el estado de carga, independientemente de éxito o error
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isLoading) {
      e.preventDefault(); // Evita que "Enter" envíe el formulario si está cargando
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  console.log(user);

  return (
    <div className="min-h-screen flex flex-col px-4 gap-7">
      {/* Header */}
      <div className="flex py-7 text-center items-center justify-between border-b border-new-border">
        <Link to="/">
          <BsArrowLeft className="text-white w-6 h-6" />
        </Link>
        <div className="font-bold font-title text-2xl text-white">BuddyIA</div>
        <div></div>
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto">
        <div className="flex flex-col space-y-4">
          {messages.map((msg, index) => (
            <Message
              key={index}
              text={msg.text}
              sender={msg.sender}
              user={user}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Chat Input */}
      <form
        onSubmit={handleSubmit}
        className="bg-chat-button mx-2 my-7 h-14 rounded-2xl flex items-center justify-between border border-chat-button-border position-fixed bottom-0 left-0 right-0 px-4 gap-4"
      >
        <div className="flex items-center gap-3 w-full">
          <StarsIcon className="w-6 h-6 text-chat-stars flex flex-shrink-0" />
          <input
            type="text"
            placeholder="Escribe aquí tu mensaje"
            value={userInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="bg-transparent font-semibold text-sm w-full text-white h-full focus:outline-none placeholder:text-white"
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? (
            <div className="w-6 h-6 rounded-full border-2 border-t-white border-r-white border-b-gray-500 border-l-gray-500 animate-spin"></div>
          ) : (
            <SendIcon className="w-6 h-6 text-white" />
          )}
        </button>
      </form>
    </div>
  );
};

export default BuddyChat;

function Message({ text, sender, user }) {
  return (
    <div className="flex gap-4">
      {/* User Icon */}
      <div className="w-7 h-7 flex justify-center items-center flex-shrink-0 rounded-full overflow-hidden">
        {sender === 'bot' ? (
          <img
            src={buddyIcon}
            alt="BuddyIA"
            className="w-full h-full object-cover"
          />
        ) : (
          <img
            src={user?.image_url}
            alt="User"
            className="w-full h-full object-cover"
          />
        )}
      </div>

      {/* Message */}
      <div className="flex flex-col gap-2">
        {/* Message User */}
        <div
          className={clsx('font-bold', {
            'text-primary': sender === 'bot',
            'text-white': sender === 'user',
          })}
        >
          {sender === 'user' ? 'Tú' : 'BuddyIA'}
        </div>

        {/* Message Text */}
        <div className="text-white">{text}</div>
      </div>
    </div>
  );
}
