import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserShoppingList from '../api/services/UserShoppingList.js';
import ShoppingListItem from '../api/services/ShoppingListItem.js';
import Measure from '../api/services/Measure.js';
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import InputState from './forms/InputState.js';
import PrimaryButton from './ui/PrimaryButton.js';
import SelectRounded from './forms/SelectRounded.js';
import { useDebounce } from '../hooks/useDebounce.js';

const ShoppingListShow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [shoppingList, setShoppingList] = useState();
  const [shoppingListItems, setShoppingListItems] = useState();
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [ingredientSearchEnabled, setIngredientSearchEnabled] = useState(true);
  const [showAddIngredientForm, setShowAddIngredientForm] = useState(false);
  const [ingredient, setIngredient] = useState(null);
  const [measureId, setMeasureId] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);
  const debouncedSearch = useDebounce(ingredientSearch, 800);

  const id = searchParams.get('id') ?? '';
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getShoppingList();
    getMeasures();
  }, []);

  useEffect(() => {
    if (debouncedSearch.length >= 3) {
      getIngredients(debouncedSearch);
    } else {
      setIngredients([]);
    }
  }, [debouncedSearch]);

  const getShoppingList = async () => {
    setLoading(true);
    try {
      const response = await UserShoppingList.get(user.id, id);
      console.log(response.data);
      setShoppingList(response.data);
      setShoppingListItems(response.data.items);
    } catch (error) {
      console.error('Error fetching organizer:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      navigate('/shoppingLists', { replace: true });
    }
  };

  const handleOnDelete = async () => {
    if (window.confirm('Eliminar Lista de la compra') === true) {
      try {
        await UserShoppingList.delete(user.id, id);
        navigate('/shoppingLists');
      } catch (error) {
        console.error('Error deleting shoppinglist:', error);
      }
    }
  };

  const handleOnUpdate = async () => {
    try {
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      await UserShoppingList.update(user.id, id, body);
      setShowEditForm(false);
      getShoppingList();
    } catch (error) {
      console.error('Error updating shoppinglist:', error);
    }
  };

  const getIngredients = async (search) => {
    try {
      // TODO: change to env variable
      const response = await fetch(
        `https://app.buddymarket.io/api/products?search=${search}`,
      );
      const { data } = await response.json();
      setIngredients(data);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
    }
  };

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      const options = response.data.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setMeasureOptions(options);
      setMeasureId(options[0].value);
    } catch (error) {
      console.error('Error fetching Measures:', error);
    }
  };

  const handleOnAddIngredient = async () => {
    if (!document.querySelector('[name="quantity"]').value) {
      alert('Es necesario que indiques la cantidad');
    } else {
      try {
        const body = new FormData();
        body.append(
          'quantity',
          document.querySelector('[name="quantity"]').value,
        );
        body.append('measure_id', measureId);
        body.append('ingredient', JSON.stringify(ingredient));

        await ShoppingListItem.create(id, body);
        handleResetIngredient();
        getShoppingList();
        setShowAddIngredientForm(false);
        document.querySelector('[name="quantity"]').value = '';
      } catch (error) {
        console.error('Error adding item:', error);
      }
    }
  };

  const handleResetIngredient = () => {
    setIngredientSearch('');
    document.querySelector('[name="ingredient_search"]').value = '';
    setIngredients([]);
    setIngredient(null);
    setIngredientSearchEnabled(true);
  };

  const handleOnFocusIngredientSearch = (e) => {
    if (ingredientSearchEnabled) {
      const ingredientSearch = e.target.value;
      if (ingredientSearch.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(e.target.value);
      }
    }
  };

  const handleOnChangeIngredientSearch = (e) => {
    const search = e.target.value;
    setIngredientSearch(search);
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  function getImage(barcode, data) {
    console.log(barcode, data);
    // Pad the barcode to 13 digits
    const paddedBarcode = barcode?.padStart(13, '0');

    // Split the barcode into folder structure
    const match = paddedBarcode.match(/^(...)(...)(...)(.*)$/);
    if (!match) {
      throw new Error('Invalid barcode format');
    }
    const folderPath = `${match[1]}/${match[2]}/${match[3]}/${match[4]}`;

    // Get the imgId from the front_es property
    const imgId = data.front_es?.imgid;
    if (!imgId) {
      throw new Error('Image ID not found in front_es');
    }

    // Construct the image URL
    const imageUrl = `https://images.openfoodfacts.org/images/products/${folderPath}/${imgId}.400.jpg`;

    return imageUrl;
  }

  const handleOnIngredientSelected = (product) => {
    const selectedIngredient = {
      id: product.id,
      name: product.product_name_es,
      image_url: getImage(product.id, product.images),
      quantity: product.quantity,
    };
    handleAddIngredientWithDefaults(selectedIngredient);
  };

  const handleAddIngredientWithDefaults = async (ingredient) => {
    try {
      console.log(ingredient);
      const body = new FormData();
      body.append('quantity', 1);
      body.append('measure_id', 1);
      body.append('ingredient', JSON.stringify(ingredient));

      await ShoppingListItem.create(id, body);
      handleResetIngredient();
      getShoppingList();
    } catch (error) {
      console.error('Error adding item with defaults:', error);
    }
  };

  const handleToggleChecked = async (index) => {
    const currentItems = [...shoppingListItems];
    currentItems[index].checked = !currentItems[index].checked;
    setShoppingListItems(currentItems);
    try {
      const body = new FormData();
      body.append('quantity', currentItems[index].quantity);
      body.append('checked', currentItems[index].checked ? 1 : 0);
      await ShoppingListItem.update(id, currentItems[index].id, body);
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleOnAddQuantity = async (index, quantity) => {
    const currentItems = [...shoppingListItems];
    const newQuantity = currentItems[index].quantity + quantity;
    if (newQuantity <= 0) {
      if (
        window.confirm(
          '¿Eliminar ' + currentItems[index].ingredient.name + ' de la lista?',
        ) === true
      ) {
        const itemId = currentItems[index].id;
        currentItems.splice(index, 1);
        setShoppingListItems(currentItems);
        try {
          await ShoppingListItem.delete(id, itemId);
        } catch (error) {
          console.error('Error deleting shoppinglist:', error);
        }
      }
    } else {
      currentItems[index].quantity = newQuantity;
      setShoppingListItems(currentItems);
      try {
        const body = new FormData();
        body.append('quantity', currentItems[index].quantity);
        body.append('checked', currentItems[index].checked ? 1 : 0);
        await ShoppingListItem.update(id, currentItems[index].id, body);
      } catch (error) {
        console.error('Error adding item:', error);
      }
    }
  };

  console.log(ingredients);

  return (
    <div className="flex flex-col w-full p-6 relative gap-4">
      {showMenu && (
        <div
          onClick={() => {
            setShowMenu(false);
          }}
          className="absolute z-30 top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60"
        >
          <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <button
              onClick={handleOnDelete}
              className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
            >
              <div className="pointer-events-none">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_545_3355)">
                    <path
                      d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                      fill="#FF8C00"
                    />
                    <g clipPath="url(#clip1_545_3355)">
                      <path
                        d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z"
                        fill="#FF8C00"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_545_3355">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_545_3355">
                      <rect
                        width="10"
                        height="10"
                        fill="white"
                        transform="translate(2)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">
                Eliminar Lista
              </span>
              <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
            </button>
            <button
              onClick={(e) => {
                setShowMenu(false);
                setShowEditForm(true);
              }}
              className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
            >
              <div className="pointer-events-none">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_545_3381)">
                    <path
                      d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                      fill="#FF8C00"
                    />
                    <g clipPath="url(#clip1_545_3381)">
                      <path
                        d="M11.8387 4.16667C11.9675 4.16667 12.0892 4.10708 12.1679 4.00542C12.2467 3.90375 12.2742 3.77125 12.2425 3.64667C12.1129 3.13958 11.8487 2.67625 11.4787 2.30625L10.0267 0.854167C9.47583 0.303333 8.74333 0 7.96417 0H6.08292C4.93458 0 4 0.934583 4 2.08333V7.91667C4 9.06542 4.93458 10 6.08333 10H7.33333C7.56333 10 7.75 9.81333 7.75 9.58333C7.75 9.35333 7.56333 9.16667 7.33333 9.16667H6.08333C5.39417 9.16667 4.83333 8.60583 4.83333 7.91667V2.08333C4.83333 1.39417 5.39417 0.833333 6.08333 0.833333H7.96458C8.0325 0.833333 8.1 0.836667 8.16667 0.842917V2.91667C8.16667 3.60583 8.7275 4.16667 9.41667 4.16667H11.8387ZM9 2.91667V1.10792C9.15792 1.19875 9.305 1.31125 9.4375 1.44375L10.8896 2.89583C11.0204 3.02667 11.1325 3.17417 11.2242 3.33333H9.41667C9.18708 3.33333 9 3.14625 9 2.91667ZM13.6342 4.94958C13.1617 4.47708 12.3383 4.47708 11.8662 4.94958L9.07167 7.74417C8.75708 8.05875 8.58333 8.4775 8.58333 8.92292V9.58375C8.58333 9.81375 8.77 10.0004 9 10.0004H9.66083C10.1062 10.0004 10.5246 9.82667 10.8392 9.51208L13.6337 6.7175C13.87 6.48125 14 6.1675 14 5.83333C14 5.49917 13.87 5.18542 13.6342 4.94958ZM13.0446 6.12792L10.2496 8.9225C10.0925 9.08 9.88333 9.16667 9.66042 9.16667H9.41625V8.9225C9.41625 8.7 9.50292 8.49083 9.66042 8.33333L12.4554 5.53875C12.6125 5.38125 12.8871 5.38125 13.0446 5.53875C13.1233 5.61708 13.1667 5.72167 13.1667 5.83333C13.1667 5.945 13.1233 6.04917 13.0446 6.12792Z"
                        fill="#FF8C00"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_545_3381">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_545_3381">
                      <rect
                        width="10"
                        height="10"
                        fill="white"
                        transform="translate(4)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">
                Editar nombre
              </span>
              <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
            </button>
          </div>
        </div>
      )}

      {showEditForm && (
        <div className="absolute top-0 z-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
          <div className="mt-40 mx-8 rounded-lg my-auto  border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setShowEditForm(false);
                }}
                className="p-1"
              >
                <XMarkIcon className="size-5" />
              </button>
            </div>
            <InputState
              name="name"
              label="Nombre de la lista"
              value={shoppingList?.name}
            />
            <PrimaryButton onClick={handleOnUpdate}>Guardar</PrimaryButton>
          </div>
        </div>
      )}

      {showAddIngredientForm && (
        <div className="absolute top-0 z-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
          <div className="mt-40 mx-8 rounded-lg my-auto  border-t border-buddy-bg-2 bg-secondary-bg p-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold">Añadir {ingredient?.label}</div>
              <button
                onClick={() => {
                  setShowAddIngredientForm(false);
                }}
                className="p-1"
              >
                <XMarkIcon className="size-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6 mt-6">
              <div className="flex flex-col">
                <span className="text-xs text-center">Unidad</span>
                <SelectRounded
                  name="measure_id"
                  onChange={handleOnChangeMeasureId}
                  options={measureOptions}
                />
              </div>
              <div className="flex flex-col text-buddy-text-1">
                <span className="text-xs mb-2 text-center">Cantidad</span>
                <div className="w-full">
                  <div className="w-full h-11 bg-secondary-bg rounded-lg relative shadow-inner ring-white ring-2">
                    <input
                      aria-autocomplete="none"
                      list="autocompleteOff"
                      className="appearance-none w-full h-full bg-transparent text-center text-sm text-white placeholder:font-light rounded-lg"
                      name="quantity"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <PrimaryButton onClick={handleOnAddIngredient}>
              Guardar
            </PrimaryButton>
          </div>
        </div>
      )}

      <Header
        handleOnBack={handleOnBack}
        setShowMenu={setShowMenu}
        shoppingList={shoppingList}
      />

      <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
        {ingredientSearch.length > 0 ? (
          <div className="absolute right-0 bottom-0 top-0">
            <button
              type="button"
              onClick={handleResetIngredient}
              className="flex items-center justify-center w-8 me-2 h-full"
            >
              <div className="w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center">
                <FontAwesomeIcon className="text-white" icon={faTimes} />
              </div>
            </button>
          </div>
        ) : (
          ''
        )}
        <input
          readOnly={!ingredientSearchEnabled}
          onFocus={handleOnFocusIngredientSearch}
          onChange={handleOnChangeIngredientSearch}
          autoComplete="new-password"
          aria-autocomplete="none"
          list="autocompleteOff"
          className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 rounded-xl"
          name="ingredient_search"
          type="text"
          placeholder="Buscador de alimentos"
        />
        <div className="absolute left-2 top-4">
          <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
        </div>

        {ingredients.length > 0 && (
          <div
            id="ingredientsList"
            className="w-full z-10 absolute flex flex-col bg-buddy-bg-5 border border-neutral-500 overflow-y-auto max-h-80 top-14 rounded-lg overflow-hidden"
          >
            {ingredients.map((product, i) => (
              <button
                key={i}
                onClick={() => handleOnIngredientSelected(product)}
                className="w-full text-start text-buddy-text-2 py-2 px-3"
              >
                {product.product_name_es} - {product.quantity} -{' '}
                {product.brands}
              </button>
            ))}
          </div>
        )}
      </div>

      {shoppingListItems && (
        <div>
          <div className="py-4 px-2">
            <div className="text-primary font-medium">
              {shoppingListItems.filter((e) => !e.checked).length} artículos
              pendientes
            </div>
          </div>
          {shoppingListItems.filter((e) => !e.checked).length > 0 ? (
            <div className="flex flex-col bg-buddy-bg-2 rounded-xl px-2 py-1 first:border-b">
              {shoppingListItems.map((item, i) => {
                if (item.checked) return null;
                return (
                  <div
                    key={i}
                    className="flex space-x-4 justify-between items-center px-1 py-3 border-b border-buddy-bg-4 last:border-b-0"
                  >
                    <div className="rounded-full bg-transparent">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt=""
                          className="bg-transparent h-10 w-10 object-cover object-center"
                        />
                      ) : (
                        <div className="h-10 w-10 bg-gray-200 flex items-center justify-center">
                          No image
                        </div>
                      )}
                    </div>
                    <div className="text-buddy-text-1 grow font-medium">
                      {item.description ? item.description : 'Ingredient name'}
                    </div>
                    {/*
                        <div className="flex flex-col items-center space-y-1">
                          <div className="text-buddy-text-1 text-sm">Cantidad</div>
                          <div className="flex justify-between space-x-1 bg-white rounded p-1">
                            <button onClick={() => { handleOnAddQuantity(i, -1); }} className="p-1">
                              <MinusIcon className="size-3" />
                            </button>
                              <div className="text-sm font-medium text-buddy-text-5">{item.quantity}{item.measure.name}</div>
                            <button onClick={() => { handleOnAddQuantity(i, 1); }} className="p-1">
                              <PlusIcon className="size-3" />
                            </button>
                          </div>
                        </div>
                        */}
                    <button
                      onClick={() => {
                        handleToggleChecked(i);
                      }}
                      className="p-3"
                    >
                      {item.checked ? (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                          <path
                            d="M7.45 11.95L12.7375 6.6625L11.6875 5.6125L7.45 9.85L5.3125 7.7125L4.2625 8.7625L7.45 11.95ZM8.5 16C7.4625 16 6.4875 15.803 5.575 15.409C4.6625 15.015 3.86875 14.4808 3.19375 13.8063C2.51875 13.1313 1.9845 12.3375 1.591 11.425C1.1975 10.5125 1.0005 9.5375 1 8.5C1 7.4625 1.197 6.4875 1.591 5.575C1.985 4.6625 2.51925 3.86875 3.19375 3.19375C3.86875 2.51875 4.6625 1.9845 5.575 1.591C6.4875 1.1975 7.4625 1.0005 8.5 1C9.5375 1 10.5125 1.197 11.425 1.591C12.3375 1.985 13.1312 2.51925 13.8062 3.19375C14.4812 3.86875 15.0157 4.6625 15.4097 5.575C15.8037 6.4875 16.0005 7.4625 16 8.5C16 9.5375 15.803 10.5125 15.409 11.425C15.015 12.3375 14.4807 13.1313 13.8062 13.8063C13.1312 14.4813 12.3375 15.0157 11.425 15.4097C10.5125 15.8038 9.5375 16.0005 8.5 16Z"
                            fill="#FF8C00"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-buddy-text-2 text-sm px-2">
              No hay artículos pendientes en esta lista
            </div>
          )}
        </div>
      )}

      {shoppingListItems && (
        <div className="mt-4">
          <div className="py-4 px-2">
            <div className="text-primary font-medium">Ya en la cesta</div>
          </div>
          {shoppingListItems.filter((e) => e.checked).length > 0 ? (
            <div className="flex flex-col bg-buddy-bg-2 rounded-xl px-2 py-1 first:border-b">
              {shoppingListItems.map((item, i) => {
                if (!item.checked) return null;
                return (
                  <div
                    key={i}
                    className="flex space-x-4 justify-between items-center px-1 py-3 border-b border-buddy-bg-4 last:border-b-0"
                  >
                    <div className="overflow-hidden rounded-full bg-transparent">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt=""
                          className="bg-transparent opacity-70 h-10 w-10 object-cover object-center"
                        />
                      ) : (
                        <div className="h-10 w-10 bg-gray-200 flex items-center justify-center">
                          No image
                        </div>
                      )}
                    </div>
                    <div className="text-buddy-text-3 grow line-through font-medium">
                      {item.description ? item.description : 'Ingredient name'}
                    </div>
                    {/*
                        <div className="flex flex-col items-center space-y-1 opacity-60">
                          <div className="text-buddy-text-1 text-sm">Cantidad</div>
                          <div className="flex justify-between space-x-1 bg-white rounded p-1">
                            <button className="p-1">
                              <MinusIcon className="size-3" />
                            </button>
                              <div className="text-sm font-medium text-buddy-text-5">{item.quantity}{item.measure.name}</div>
                            <button className="p-1">
                              <PlusIcon className="size-3" />
                            </button>
                          </div>
                        </div>
                        */}
                    <button
                      onClick={() => {
                        handleToggleChecked(i);
                      }}
                      className="p-3"
                    >
                      {item.checked ? (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                          <path
                            d="M7.45 11.95L12.7375 6.6625L11.6875 5.6125L7.45 9.85L5.3125 7.7125L4.2625 8.7625L7.45 11.95ZM8.5 16C7.4625 16 6.4875 15.803 5.575 15.409C4.6625 15.015 3.86875 14.4808 3.19375 13.8063C2.51875 13.1313 1.9845 12.3375 1.591 11.425C1.1975 10.5125 1.0005 9.5375 1 8.5C1 7.4625 1.197 6.4875 1.591 5.575C1.985 4.6625 2.51925 3.86875 3.19375 3.19375C3.86875 2.51875 4.6625 1.9845 5.575 1.591C6.4875 1.1975 7.4625 1.0005 8.5 1C9.5375 1 10.5125 1.197 11.425 1.591C12.3375 1.985 13.1312 2.51925 13.8062 3.19375C14.4812 3.86875 15.0157 4.6625 15.4097 5.575C15.8037 6.4875 16.0005 7.4625 16 8.5C16 9.5375 15.803 10.5125 15.409 11.425C15.015 12.3375 14.4807 13.1313 13.8062 13.8063C13.1312 14.4813 12.3375 15.0157 11.425 15.4097C10.5125 15.8038 9.5375 16.0005 8.5 16Z"
                            fill="#FF8C00"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-buddy-text-2 text-sm px-2">
              Aquí aparecen los artículos marcados como completados
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShoppingListShow;

const Header = ({ handleOnBack, setShowMenu, shoppingList }) => {
  return (
    <div className="w-full h-12 flex justify-between items-center text-title text-lg">
      <button
        onClick={handleOnBack}
        className="flex justify-center items-center w-10 h-10 rounded-full bg-white bg-opacity-20"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="grow text-center">
        <div className="font-bold">{shoppingList?.name}</div>
      </div>
      <button
        onClick={() => {
          setShowMenu((prevMenu) => !prevMenu);
        }}
      >
        <EditIcon />
      </button>
    </div>
  );
};

const EditIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="12" width="4" height="4" rx="2" fill="white" />
  </svg>
);
