import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import thanksImage from '../img/gracias_rec.png';
import Toolbar from './ui/Toolbar';
import PrimaryButton from './ui/PrimaryButton';
import Auth from '../api/services/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import Card from './ui/Card';

const SignUpConfirm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [failMessage, setFailMessage] = useState('');

  useEffect(() => {
    setLoading(false);
    postRegister();
  }, []);

  async function goLogin() {
    navigate('/login');
  }

  async function postRegister() {
    setLoading(true);
    try {
      const data = JSON.parse(localStorage.getItem('register_data'));
      const response = await Auth.register(data);
      console.log(response);
    } catch (error) {
      setFailMessage(error.response.payload.message ?? '');
      setFailed(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form className="w-full">
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to="/profileFormPremiumPayment" replace={true}></Toolbar>
            {loading ? (
              <div className="absolute inset-0 flex justify-center items-center h-screen">
                <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
              </div>
            ) : failed ? (
              <Card className="bg-primary-bg bg-opacity-100 text-white mt-28">
                <div className="w-full text-center">
                  <FontAwesomeIcon
                    size="8x"
                    className="inline text-red-700 z-10 -mt-28 mb-8"
                    icon={faTimesCircle}
                  />
                </div>
                <h2 className="text-center font-semibold text-2xl">
                  Algo ha salido mal
                </h2>
                <p className="text-center text-body my-6">
                  No se ha podido completar el registro
                </p>
                {failMessage ? (
                  <div className="mb-4 mx-4">
                    <p>Error: {failMessage}</p>
                  </div>
                ) : (
                  ''
                )}
                <PrimaryButton type="button" onClick={postRegister}>
                  Reintentar
                </PrimaryButton>
              </Card>
            ) : (
              <Card className="bg-primary-bg bg-opacity-100 text-white mt-36">
                <div className="w-full text-center">
                  <img
                    className="inline w-1/2 z-10 -mt-36 mb-8 "
                    src={thanksImage}
                    alt="Gracias por registrarte"
                  />
                </div>
                <h2 className="text-center font-semibold text-2xl">
                  ¡Gracias por registrarte en BuddyMarket!
                </h2>
                <p className="text-center text-body my-6">
                  Tu cuenta ha sido creada con éxito. Expera un momento, nos
                  estamos preparando para ti...
                </p>
                <PrimaryButton type="button" onClick={goLogin}>
                  Empezar a usar BuddyMarket
                </PrimaryButton>
              </Card>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpConfirm;
