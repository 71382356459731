export default function CheckIcon({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0007 15.1728L19.1931 5.98047L20.6073 7.39468L10.0007 18.0012L3.63672 11.6373L5.05093 10.2231L10.0007 15.1728Z"
        fill="#05FF00"
      />
    </svg>
  );
}
