import React, { useState, useEffect } from 'react';
import User from '../../api/services/User';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

const UserTile = ({ user, to = '' }) => {
  const [img, setImg] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //setLoading(true);
    //getImage();
  }, []);

  const getImage = async () => {
    try {
      const response = await User.image(user.id);
      const blob = await response.blob();
      setImg(URL.createObjectURL(blob));
    } catch (error) {
      console.error('Error fetching recipes:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Link to={to} className="relative w-16 mr-5 mb-2 rounded-lg">
        <div className="relative w-16 pointer-events-none">
          <div className="relative overflow-hidden rounded-full bg-black">
            {loading ? (
              <div className="bg-black h-16 w-16 object-cover flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="animate-spin text-white"
                />
              </div>
            ) : (
              <img
                src={user.image_url}
                alt=""
                className="bg-black h-16 w-16 object-cover object-center"
              />
            )}
          </div>
          <div className="absolute -right-1 -top-1">
            <CheckBadgeIcon className="size-7 text-primary" />
          </div>
        </div>
        <div className="w-full mt-2 text-center overflow-hidden text-buddy-text-1 text-xs">
          {user.name}
        </div>
      </Link>
    </>
  );
};

export default UserTile;
