import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CheckIcon, ChevronRightIcon, PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MenuOrganizerDayPart from "../api/services/MenuOrganizerDayPart.js";
import MenuOrganizerDayPartRecipe from "../api/services/MenuOrganizerDayPartRecipe.js";
import User from "../api/services/User.js";
import SmallToggle from "./forms/SmallToggle.js";
import PrimaryButton from "./ui/PrimaryButton.js";
import InputState from "./forms/InputState.js";
import Recipe from "../api/services/Recipe";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuOrganizerDayPartShow = () => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [menuOrganizerDayPart, setMenuOrganizerDayPart] = useState();
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showSelectRecipeView, setShowSelectRecipeView] = useState(false);
  const [showDeleteRecipeForm, setShowDeleteRecipeForm] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");

  const [searchMode, setSearchMode] = useState(1); // Defaults to "Favoritas"
  const searchModeOptions = [
    { value: 1, label: "Favoritas" },
    { value: 2, label: "Todas las recetas" }
  ];

  const id = searchParams.get('id') ?? '';
  const dayPartId = searchParams.get('part_id') ?? '';
  const partLabel = searchParams.get('part_label') ?? '';
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => { 
    getMenuOrganizerDayPart();
  }, []);

  useEffect(() => {
    if (showSelectRecipeView) {
      getRecipes();
    }
  }, [search, searchMode]);

  const getMenuOrganizerDayPart = async () => {
    setLoading(true);
    try {
      const response = await MenuOrganizerDayPart.get(id, dayPartId);
      setMenuOrganizerDayPart(response.data);
    } catch (error) {
      console.error('Error fetching organizer day part:', error);
    } finally {
      setLoading(false);
    }
  };

  const getRecipes = async () => {
    setRecipes([]);
    setLoading(true);
    try {
      if (searchMode === 1) { // "Favoritas"
        const response = await User.favoriteRecipesList(user.id, { limit: 10, search });
        setRecipes(response.data);
      } else if (searchMode === 2) { // "Creadas por mí"
        const response = await Recipe.list({ limit: 10, search });
        setRecipes(response.data);
      }
    } catch (error) {
      console.error('Error fetching recipes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnRecipeSelected = async (recipeId) => {
    try {
      await MenuOrganizerDayPartRecipe.create(dayPartId, recipeId);
      setShowSelectRecipeView(false);
      getMenuOrganizerDayPart();
    } catch (error) {
      console.error('Error adding recipe to day part:', error);
    }
  };

  const handleOnUpdate = async () => {
    try {
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      await MenuOrganizerDayPart.update(id, dayPartId, body);
      setShowEditForm(false);
      getMenuOrganizerDayPart();
    } catch (error) {
      console.error('Error updating day part:', error);
    }
  };

  const handleOnDelete = async () => {
    if (window.confirm("¿Eliminar esta Comida?") === true) {
      try {
        await MenuOrganizerDayPart.delete(id, dayPartId);
        setShowEditForm(false);
        navigate(-1);
      } catch (error) {
        console.error('Error deleting day part:', error);
      }
    }
  };

  const handleOnDeleteRecipe = async (id, name) => {
    if (window.confirm("¿Quitar " + name + "?") === true) {
      try {
        await MenuOrganizerDayPartRecipe.delete(dayPartId, id);
        setShowDeleteRecipeForm(false);
        getMenuOrganizerDayPart();
      } catch (error) {
        console.error('Error deleting recipe:', error);
      }
    }
  };

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      navigate(-1, { replace: true });
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleResetSearch = () => {
    setSearch('');
  };

  const handleSearchModeChange = (mode) => {
    setSearchMode(mode);
    handleResetSearch();
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        {/* Navigation Button and Title */}
        <div className='w-full h-12  flex justify-start text-title items-center text-lg mb-2'>
          <div onClick={ handleOnBack } className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
              <button className='text-title'><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path></svg></button>
          </div>
          <div className='grow text-center'>
              <div className='font-bold '>{partLabel}</div>
          </div>
          <button onClick={() => setShowMenu(!showMenu)}>
            {/* Menu icon */}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_543_3313)">
            <rect x="6" width="4" height="4" rx="2" fill="white"/>
            <rect x="6" y="6" width="4" height="4" rx="2" fill="white"/>
            <rect x="6" y="12" width="4" height="4" rx="2" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_543_3313">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
            </svg>
          </button>
        </div>

        {/* Recipe List */}
        <div className="mt-8">
            {
              menuOrganizerDayPart && 
              <div>
                <div className="w-full text-start bg-form-control-bg px-6 py-4 flex justify-between items-center rounded-lg mb-2">
                  <div className="flex flex-col pointer-events-none">
                    <span className="text-buddy-text-1 font-medium pointer-events-none">{menuOrganizerDayPart.name}</span>
                  </div>
                </div>
                <div className="flex flex-col space-y-2 mt-3">
                  {
                    menuOrganizerDayPart.recipes.map(recipe => (
                      <Link to={"/recipe?id=" + recipe.id} key={recipe.id} className="flex bg-buddy-bg-2 justify-between items-center space-x-3 rounded-xl px-4 py-3 text-buddy-text-1 font-medium">
                        <div className="overflow-hidden rounded-lg bg-transparent">
                          <img src={recipe.image_url} alt="" className="bg-transparent h-12 w-12 object-cover object-center" />
                        </div>
                        <div className="grow ">{recipe.name}</div>
                        <div>
                          <ChevronRightIcon className="size-4"></ChevronRightIcon>
                        </div>
                      </Link>
                    ))
                  }
                </div>
                <div className="flex flex-col items-center">
                  <button onClick={() => {getRecipes(); setShowSelectRecipeView(true)}} className="flex items-center justify-center w-28 mx-6 h-28 mb-2 rounded-lg">
                    <div className="flex items-center justify-center w-12 h-12 rounded p-2 bg-buddy-bg-3">
                        <PlusIcon className='size-6 text-primary grow-0' />
                    </div>
                  </button>
                </div>
              </div>
            }
        </div>

        {/* Menu and Recipe Actions */}
        { showMenu && (
          <div className="absolute top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
            <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <button onClick={() => { setShowMenu(false); setShowEditForm(true) }} className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3">
                <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">Editar Nombre</span>
                <ChevronRightIcon className="size-4 pointer-events-none"/>
              </button>
              <button onClick={handleOnDelete} className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3">
                <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">Eliminar Comida</span>
                <ChevronRightIcon className="size-4 pointer-events-none"/>
              </button>
              <button onClick={() => { setShowMenu(false); setShowDeleteRecipeForm(true) }} className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3">
                <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">Eliminar Plato</span>
                <ChevronRightIcon className="size-4 pointer-events-none"/>
              </button>
            </div>
          </div>
        )}

        {/* Edit Form */}
        { showEditForm && (
          <div className="absolute top-0 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
            <div className="mt-40 mx-8 rounded-lg my-auto border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <div className="flex justify-end">
                <button onClick={() => setShowEditForm(false)} className="p-1">
                  <XMarkIcon className="size-5" />
                </button>
              </div>
              <InputState name="name" label="Nombre de la Comida" value={menuOrganizerDayPart.name} />
              <PrimaryButton onClick={handleOnUpdate}>Guardar</PrimaryButton>
            </div>
          </div>
        )}

        {/* Select Recipe View */}
        { showSelectRecipeView && (
          <div className="absolute top-0 left-0 w-full py-8 min-h-screen text-white bg-black bg-opacity-60">
            <div className="mx-8 rounded-lg my-auto h-full border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <div className="flex justify-between items-center">
                <span>Elige tus recetas</span>
                <button onClick={() => setShowSelectRecipeView(false)} className="p-1">
                  <XMarkIcon className="size-5" />
                </button>
              </div>
              <SmallToggle 
                selected={searchMode}
                onChange={handleSearchModeChange}
                options={searchModeOptions}
              />
              <div className="flex flex-col space-y-2 overflow-y-scroll">
                  <div className='grow text-center mb-4'>
                    <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
                      <div className="absolute h-12 flex flex-row items-center">
                        <div className='w-8 ps-4'>
                          <FontAwesomeIcon className='text-buddy-text-3' icon={faSearch} />
                        </div>
                      </div>
                      <input type="text" onChange={handleSearchChange} autoComplete="off" className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl" name="search" placeholder="Buscador de recetas" value={search} />
                      <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                        <button type='button' onClick={handleResetSearch} className='flex items-center justify-center w-8 me-2 h-full'>
                          <div className='w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center'>
                            <FontAwesomeIcon className='text-white' icon={faTimes} />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>                
                  {
                  recipes.map(recipe => (
                    <button key={recipe.id} onClick={() => handleOnRecipeSelected(recipe.id)} className="w-full flex bg-buddy-bg-2 justify-between items-center space-x-3 rounded-xl px-2 py-2 text-buddy-text-1 font-medium">
                      <div className="overflow-hidden rounded-lg bg-transparent">
                        <img src={recipe.image_url} alt="" className="bg-transparent h-10 w-10 object-cover object-center" />
                      </div>
                      <div className="grow text-start">{recipe.name}</div>
                      <div className="px-2">
                        <CheckIcon className="size-4"></CheckIcon>
                      </div>
                    </button>
                  ))
                }
                {
                  recipes.length === 0 && !loading && 
                  <div className="text-buddy-text-2 text-sm">
                    No se han encontrado recetas
                  </div>
                }
              </div>
            </div>
          </div>
        )}

        {/* Delete Recipe View */}
        { showDeleteRecipeForm && (
          <div className="absolute top-0 left-0 w-full py-8 min-h-screen text-white bg-black bg-opacity-60">
            <div className="mx-8 rounded-lg my-auto h-full border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <div className="flex justify-between items-center">
                <span>Elimina recetas de esta Comida</span>
                <button onClick={() => setShowDeleteRecipeForm(false)} className="p-1">
                  <XMarkIcon className="size-5" />
                </button>
              </div>
              <div className="flex flex-col space-y-2 overflow-y-scroll">
                {
                  menuOrganizerDayPart.recipes.map(recipe => (
                    <button key={recipe.id} onClick={() => handleOnDeleteRecipe(recipe.id, recipe.name)} className="w-full flex bg-buddy-bg-2 justify-between items-center space-x-3 rounded-xl px-2 py-2 text-buddy-text-1 font-medium">
                      <div className="overflow-hidden rounded-lg bg-transparent">
                        <img src={recipe.image_url} alt="" className="bg-transparent h-10 w-10 object-cover object-center" />
                      </div>
                      <div className="grow text-start">{recipe.name}</div>
                      <div className="px-2">
                        <TrashIcon className="size-4 text-buddy-text-danger"></TrashIcon>
                      </div>
                    </button>
                  ))
                }
                {
                  menuOrganizerDayPart.recipes.length === 0 && 
                  <div className="text-buddy-text-2 text-sm">
                    Aún no has añadido recetas a esta Comida.
                  </div>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuOrganizerDayPartShow;