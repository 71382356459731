import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import AgeRange from '../api/services/AgeRange';

const ProfileForm1 = () => {
  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      label: 'Individual',
    },
    {
      value: 2,
      label: 'BuddyMaker',
    },
  ];
  const [buyerTypeId, setBuyerTypeId] = useState(1);

  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data'));
    data.buyer_type_id = buyerTypeId;

    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm8');
  };

  const handleBuyerTypeChange = (value) => {
    setBuyerTypeId(value);
  };

  useEffect(() => {
    async function loadPersonsAgesRanges() {
      try {
        const response = await AgeRange.list();
        var options = [];
        response.data.forEach((range) => {
          options.push({
            id: range.id,
            name: range.name,
          });
        });
        loadStoredData();
      } catch (error) {
        console.log(error);
      }
    }

    window.scrollTo(0, 0);

    var personsOptions = [];
    for (var i = 1; i <= 10; i++) {
      personsOptions.push({
        value: i,
        label: i,
      });
    }

    loadPersonsAgesRanges();
  }, []);

  function loadStoredData() {
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setBuyerTypeId(data.buyer_type_id);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/signUp'} replace={true}>
              <div className="text-sm">
                <span>Paso 1</span> <span className="text-primary">de 4</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              Tipo de Usuario
            </h2>
            <SelectList
              onChange={handleBuyerTypeChange}
              selected={buyerTypeId}
              options={options}
            />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm1;
