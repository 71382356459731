import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import menus from '../img/menus.png';

const Menus = () => {
  const { id } = useParams();
  const [weekData, setWeekData] = useState(null);

  useEffect(() => {
    const today = new Date();
    const hoy = id === '1' ? today : new Date(Date.parse(id));
    const diadehoy = formatDateSql(today.toLocaleDateString());
    const weekNumber = getWeekNumber(hoy);
    const weekDates = getWeekDates(hoy);
    const todayFormatted = hoy.toLocaleDateString();
    const todaySql = formatDateSql(todayFormatted);
    const todayIndex = weekDates.findIndex(
      (date) => date.toLocaleDateString() === todayFormatted,
    );
    const lastWeek = restaSemana(todaySql);
    const nextWeek = sumaSemana(todaySql);

    setWeekData({
      weekNumber,
      weekDates,
      todayIndex,
      todayFormatted,
      todaySql,
      nextWeek,
      lastWeek,
      diadehoy,
    });
  }, [id]);

  function formatDateSql(dateString) {
    const [day, month, year] = dateString.split('/');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const daysSinceStartOfYear = Math.floor(
      (date - firstDayOfYear) / (1000 * 60 * 60 * 24),
    );
    return Math.ceil((daysSinceStartOfYear + 1) / 7);
  }

  function getWeekDates(date) {
    const dayOfWeek = date.getDay();
    const mondayDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - dayOfWeek + 1,
    );
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const dayDate = new Date(
        mondayDate.getFullYear(),
        mondayDate.getMonth(),
        mondayDate.getDate() + i,
      );
      weekDates.push(dayDate);
    }
    return weekDates;
  }

  function restaSemana(dia) {
    const date = new Date(Date.parse(dia));
    date.setDate(date.getDate() - 7);
    return date
      .toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/')
      .reverse()
      .join('-');
  }

  function sumaSemana(dia) {
    const date = new Date(Date.parse(dia));
    date.setDate(date.getDate() + 7);
    return date
      .toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/')
      .reverse()
      .join('-');
  }

  if (!weekData) {
    return <div>Cargando...</div>;
  }

  const {
    weekNumber,
    weekDates,
    todayIndex,
    todaySql,
    nextWeek,
    lastWeek,
    diadehoy,
  } = weekData;

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={'../dashboard'}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Menús</h2>
      </div>

      <div className="maxrectangulo centrado mb0">
        <img className="portada" src={menus} alt="" />
      </div>
      <div className="rectangulo centrado p0">
        <Link to={`../agregar-receta-menu/${todaySql}`}>
          <button className="round fondonaranja textoblanco p10">
            Añadir receta
          </button>
        </Link>
      </div>
      <div className="rectangulo flex equiespaciado mt0 p0">
        <div className="w10">
          <Link to={`../menus/${lastWeek}`}>
            <span className="inline simbologran izquierda">
              <FontAwesomeIcon icon={faCircleArrowLeft} className="" />
            </span>
          </Link>
        </div>
        <div className="centrado">
          <p className="textoblanco">Semana {weekNumber}</p>
          <h3 className="textoblanco">
            {weekDates[0].toLocaleDateString() +
              ' - ' +
              weekDates[6].toLocaleDateString()}
          </h3>
        </div>
        <div className="w10">
          <Link to={`../menus/${nextWeek}`}>
            <span className="inline simbologran derecha">
              <FontAwesomeIcon icon={faCircleArrowRight} className="" />
            </span>
          </Link>
        </div>
      </div>

      <div className="rectangulo mt0 p0 mb20">
        {weekDates.map((date, index) => (
          <div className="w100 mb10" key={index}>
            <Link
              className="flex spacebetween vcentrado linkhover p0-10"
              to={`../menu-dia/${formatDateSql(date.toLocaleDateString())}`}
            >
              <span
                className={
                  index === todayIndex && diadehoy === todaySql
                    ? 'textonaranja'
                    : 'textoblanco'
                }
              >
                <strong>
                  {
                    [
                      'Lunes',
                      'Martes',
                      'Miércoles',
                      'Jueves',
                      'Viernes',
                      'Sábado',
                      'Domingo',
                    ][index]
                  }
                </strong>
              </span>
              <span className="inline simbologran derecha">&gt;</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menus;
