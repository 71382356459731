import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import CookingTimeRange from '../api/services/CookingTimeRange';

const ProfileForm12 = () => {
  const navigate = useNavigate();

  const [dailyMealsOptions, setDailyMealsOptions] = useState([]);
  const [cookingTimeRangeOptions, setCookingTimeRangeOptions] = useState([]);
  const [dailyMeals, setDailyMeals] = useState('');
  const [cookingTimeRangeId, setCookingTimeRangeId] = useState('');
    
  const handleClick = (e) => {
    if (dailyMeals !== '' && cookingTimeRangeId !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.daily_meals = parseInt(document.querySelector('[name="daily_meals"]').value);
      data.cooking_time_range_id = parseInt(document.querySelector('[name="cooking_time_range_id"]').value);
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/signUpConfirm');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  }

  const handleOnChangeDailyMeals = (e) => {
    setDailyMeals(e.target.value);
  }
  const handleOnChangeCookingTimeRangeId = (e) => {
    setCookingTimeRangeId(e.target.value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    loadDailyMealsOptions();
    loadCookingTimeRangesOptions();
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setDailyMeals(data.daily_meals ?? '');
      setCookingTimeRangeId(data.cooking_time_range_id ?? '');
    }
  }, []);

  async function loadDailyMealsOptions() {

    var options = [];
    for (var i = 0; i < 10; i++) {
      options.push({
        value: i,
        label: i
      });
    }
    setDailyMealsOptions(options);
  }

  async function loadCookingTimeRangesOptions() {
    try {
      const response = await CookingTimeRange.list();
      var options = [];
      response.data.map(range => {
        options.push({
          value: range.id,
          label: range.name
        });
      });
      setCookingTimeRangeOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm10"} replace={true}>
              <div className='text-sm'>
                <span >Paso 7</span> <span className='text-primary'>de 7</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cuántas comidas realizas al día?</h2>
            <Select placeholder={'Seleccionar'} placeholderValue='' onChange={handleOnChangeDailyMeals} value={dailyMeals} key="daily_meals" name="daily_meals" options={dailyMealsOptions} />
            <div className='my-3'></div>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cuánto tiempo prefieres dedicar a la preparación de cada comida?</h2>
            <Select placeholder={'Seleccionar'} placeholderValue='' onChange={handleOnChangeCookingTimeRangeId} value={cookingTimeRangeId} key="cooking_time_range_id" name="cooking_time_range_id" options={cookingTimeRangeOptions} />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm12;