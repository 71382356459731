import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import MealPlanningFrequency from '../api/services/MealPlanningFrequency';
import CaloriesNutrientsTrackingImportance from '../api/services/CaloriesNutrientsTrackingImportance';

const ProfileForm14 = () => {
  const navigate = useNavigate();

  const [mealPlanningFrequencyId, setMealPlanningFrequencyId] = useState('');
  const [mealPlanningFrequencyOptions, setMealPlanningFrequencyOptions] = useState([]);
  const [calorieAndNutrientTrackingImportanceId, setCalorieAndNutrientTrackingImportanceId] = useState('');
  const [calorieAndNutrientTrackingImportanceOptions, setCalorieAndNutrientTrackingImportanceOptions] = useState([]);
 
  const handleClick = (e) => {
    if (mealPlanningFrequencyId !== '' && calorieAndNutrientTrackingImportanceId !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.meal_planning_frequency_id = mealPlanningFrequencyId;
      data.calories_nutrients_tracking_importance_id = calorieAndNutrientTrackingImportanceId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm15');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  }

  const handleOnChangeMealPlanningFrequancyId = (value) => {
    setMealPlanningFrequencyId(value);
  }

  const handleOnChangeCalorieAndNutrientTrackingImportanceId = (value) => {
    setCalorieAndNutrientTrackingImportanceId(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    loadPlanningFrequencyOptions();
    loadCalorieAndNutrientTrackingImportanceOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setMealPlanningFrequencyId(data.meal_planning_frequency_id ?? '');
      setCalorieAndNutrientTrackingImportanceId(data.calories_nutrients_tracking_importance_id ?? ''); 
    }
  }, []);

  async function loadPlanningFrequencyOptions() {
    try {
      const response = await MealPlanningFrequency.list();
      var options = [];
      response.data.map(frequency => {
        options.push({
          value: frequency.id,
          label: frequency.name
        });
      });
      setMealPlanningFrequencyOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCalorieAndNutrientTrackingImportanceOptions() {
    try {
      const response = await CaloriesNutrientsTrackingImportance.list();
      var options = [];
      response.data.map(importance => {
        options.push({
          value: importance.id,
          label: importance.name
        });
      });
      setCalorieAndNutrientTrackingImportanceOptions(options);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className='min-h-screen bg-no-repeat bg-cover bg-center'>
      <div className='min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm13"} replace={true}>
              <div className='text-sm'>
                <span >Paso 14</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Planificas tus comidas con anticipación?</h2>
            <SelectList onChange={handleOnChangeMealPlanningFrequancyId} selected={mealPlanningFrequencyId} options={mealPlanningFrequencyOptions} />
            <div className='my-2'></div>
            <h2 className='w-full text-white text-xl font-semibold'>¿Qué tan importante es para ti el seguimiento del consumo de calorías y nutrientes?</h2>
            <SelectList onChange={handleOnChangeCalorieAndNutrientTrackingImportanceId} selected={calorieAndNutrientTrackingImportanceId} options={calorieAndNutrientTrackingImportanceOptions} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm14;